/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TYPES } from 'sarsaparilla';

import EditEmployee from '../components/cooperator/EditEmployee';
import PageHeader from '../components/PageHeader';
import SiteWrapper from './SiteWrapper';

import { saveEmployees, deleteEmployees } from '../actions/manageCooperators';
import { resendConfirmation } from '../actions/updateUser';
import { enablePasswordReset } from '../actions/resetPassword';
import {
    errorForCooperators,
    errorForSavingCooperatorEmployees,
} from '../utilities/errorMessages';
import * as errors from '../constants/errors';

export class EditEmployeeContainer extends React.Component {
    static propTypes = {
        history: TYPES.HISTORY,
        cooperator: PropTypes.any,
        employee: PropTypes.any,
        savingManagedCooperatorsError: PropTypes.bool,
        savingCooperatorEmployeesError: PropTypes.bool,
        errorEnablingPasswordReset: PropTypes.any,
        userIdEnabledPasswordReset: PropTypes.any,
        requestConfirmResponse: PropTypes.any,

        saveEmployees: PropTypes.func,
        resetPassword: PropTypes.func,
        resendConfirmation: PropTypes.func,
    };

    saveEmployees = (employees, add) => {
        const emps = employees || [this.props.employee];
        this.props.saveEmployees(emps, add, this.props.history);
    };

    resetPassword = (user) => {
        this.props.resetPassword(user.user_id);
    };

    resendConfirmation = (user) => {
        this.props.resendConfirmation(user);
    };

    render() {
        const {
            history,
            savingCooperatorEmployeesError,
            savingManagedCooperatorsError,
            cooperator,
            employee,
            userIdEnabledPasswordReset,
            errorEnablingPasswordReset,
            requestConfirmResponse,
        } = this.props;

        let error = '';
        let navBackUrl = null;
        const crumbs = [];
        const tabs = [
            {
                title: 'Cooperators',
            },
        ];

        error = savingCooperatorEmployeesError
            ? errorForSavingCooperatorEmployees(savingCooperatorEmployeesError)
            : error;

        error = savingManagedCooperatorsError
            ? errorForCooperators(errors.SAVE_COOPERATORS_FAILED)
            : error;

        navBackUrl = '/internal/account/manage-cooperators';
        crumbs.push({
            label: 'Cooperators',
            onClick: () => history.push('/internal/account/manage-cooperators'),
        });

        if (cooperator.cooperator_id) {
            navBackUrl = '/internal/account/cooperator-details';
            crumbs.push({
                label: 'Cooperator Details',
                onClick: () => history.push('/internal/account/cooperator-details'),
            });
        }

        if (cooperator && cooperator.cooperator_id && employee) {
            crumbs.push({
                label: employee.user_id ? 'Edit Employee' : 'Add Employee',
            });
        } else if (cooperator) {
            crumbs.push({
                label: cooperator.cooperator_id ? 'Edit Cooperator' : 'Add Cooperator',
            });
        }

        return (
            <SiteWrapper>
                <div className="page-title">
                    <PageHeader
                        title="Cooperator Management"
                        tabs={tabs}
                        breadcrumbs={crumbs}
                        navBack={navBackUrl}
                        selectedTabIndex={0}
                    />
                </div>
                <div id="CooperatorManagement" className="page-content wrapper">
                    <EditEmployee
                        history={history}
                        employee={employee}
                        errorText={error}
                        employeeActionStatus={{
                            userIdEnabledPasswordReset,
                            errorEnablingPasswordReset,
                            requestConfirmResponse,
                        }}
                        saveEmployees={this.saveEmployees}
                        resetPassword={this.resetPassword}
                        resendConfirmation={this.resendConfirmation}
                    />
                </div>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cooperator: state.manageCooperatorsSet.managedCooperator,
        employee: state.manageCooperatorsSet.cooperatorEmployee,
        savingManagedCooperatorsError:
            state.manageCooperators.savingManagedCooperatorsError,
        savingCooperatorEmployeesError:
            state.manageCooperators.savingCooperatorEmployeesError,
        userIdEnabledPasswordReset: state.updateUser.userIdEnabledPasswordReset,
        errorEnablingPasswordReset: state.updateUser.errorEnablingPasswordReset,
        requestConfirmResponse: state.updateUser.requestConfirmResponse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveEmployees: (employees, add, history) =>
            dispatch(saveEmployees(employees, add, history)),
        deleteEmployees: (employees) => dispatch(deleteEmployees(employees)),
        resetPassword: (userId) => dispatch(enablePasswordReset(userId)),
        resendConfirmation: (user) => dispatch(resendConfirmation(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployeeContainer);
