/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Button,
    ButtonGroup,
    Icons,
    ModalActions,
    Text,
    StyledModal,
} from 'sarsaparilla';
import {
    attestFetchLoadingSelector,
    attestFetchSelector,
    attestStateSelector,
} from '../reducers/attestation';
import {
    attestUsers,
    fetchAttestation,
    fetchAttestationReset,
} from '../actions/attestation';
import { ATR, displayStringForRoleType, FACILITY_MANAGER, PMO } from '../utilities/roles';
import Toast from '../components/Toast';

const propTypes = {
    attestState: PropTypes.shape({
        location: PropTypes.object,
        role: PropTypes.string,
    }).isRequired,
    attestData: PropTypes.shape({
        attested_at: PropTypes.string,
    }).isRequired,
    loading: PropTypes.bool,
    loadingPost: PropTypes.bool,
    onAttest: PropTypes.func.isRequired,
    fetchAttestation: PropTypes.func.isRequired,
    fetchAttestationReset: PropTypes.func.isRequired,
    loggedInUser: PropTypes.object,
    userResults: PropTypes.any,
};

export class Attestation extends React.PureComponent {
    static propTypes = propTypes;

    state = {
        isModalOpened: false,
        usersToAttest: [],
        showToast: false,
    };

    componentDidUpdate(prevProps) {
        const {
            attestState: { location, role },
            loading,
        } = this.props;
        if (role !== prevProps.attestState.role && !loading) {
            if (role) {
                this.props.fetchAttestation(
                    location.location_type,
                    location.location_id,
                    role
                );
            } else {
                this.props.fetchAttestationReset();
            }
        }
    }

    shouldDisplay() {
        const { loggedInUser } = this.props;

        if (!loggedInUser) {
            return false;
        }

        for (const role of loggedInUser.roles) {
            if (
                role.role_type === FACILITY_MANAGER &&
                role.can_attest_to_peers === true
            ) {
                return true;
            }
            if (
                role.role_type === FACILITY_MANAGER ||
                role.role_type === PMO ||
                role.role_type === ATR
            ) {
                return true;
            }
        }
        return false;
    }

    openConfirmationModal() {
        const {
            attestState: { location, role },
            userResults,
        } = this.props;
        const attestedUsers = userResults.filter((user) => {
            const roles = user?.roles || [];
            const filteredRoles = roles.filter(
                (userRole) =>
                    userRole.role_type === role &&
                    userRole.location_id === location.location_id
            );
            return filteredRoles.length > 0;
        });
        this.setState({
            isModalOpened: true,
            usersToAttest: attestedUsers,
        });
    }

    async attestUsers() {
        const {
            attestState: { location, role },
        } = this.props;
        await this.props.onAttest(location.location_type, location.location_id, role);
        this.setState({ isModalOpened: false });
        this.toggleShowToast();
    }

    toggleShowToast() {
        this.setState((prevState) => ({ showToast: !prevState.showToast }));
    }

    render() {
        const {
            attestState: { location, role },
            attestData,
            loadingPost,
            userResults,
            loggedInUser,
        } = this.props;
        const ninetyDaysAgo = moment().add('day', -90);
        if (!this.shouldDisplay()) {
            return null;
        }

        const attestedUsers = userResults?.filter((user) => {
            const roles = user?.roles || [];
            const filteredRoles = roles.filter(
                (userRole) =>
                    userRole.role_type === role &&
                    userRole.location_id === location.location_id
            );
            return filteredRoles.length > 0;
        });

        const isDisabled = !(
            !!location.location_id &&
            !!location.location_type &&
            !!role &&
            attestedUsers?.length
        );
        const userRole = loggedInUser.roles.find(
            (loggedInUserRole) => loggedInUserRole.location_id === location.location_id
        );
        const isFMAttestingFMs =
            userRole?.role_type === FACILITY_MANAGER && role === FACILITY_MANAGER;
        const cannotFMAttestToFMs = isFMAttestingFMs && !userRole?.can_attest_to_peers;

        return (
            <div className="attestation-wrapper">
                <Toast
                    msg={`Attestation completed for ${this.state.usersToAttest.length} users.`}
                    show={this.state.showToast}
                    setShow={this.toggleShowToast.bind(this)}
                />
                <StyledModal
                    size="md"
                    isOpen={this.state.isModalOpened}
                    heading="Confirmation"
                    onRequestClose={() => this.setState({ isModalOpened: false })}
                >
                    <Text>
                        By proceeding with this attestation, you are confirming that these{' '}
                        {this.state.usersToAttest.length} users all have the{' '}
                        {displayStringForRoleType(this.props.attestState.role)} role at{' '}
                        {location.location_name}.
                    </Text>
                    <ModalActions>
                        <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                            <Button
                                appearance="tertiary"
                                onClick={() => this.setState({ isModalOpened: false })}
                            >
                                Cancel
                            </Button>
                            <Button onClick={this.attestUsers.bind(this)}>Ok</Button>
                        </ButtonGroup>
                    </ModalActions>
                </StyledModal>
                <span>
                    <Button
                        isLoading={loadingPost}
                        appearance="secondary"
                        isDisabled={isDisabled || cannotFMAttestToFMs}
                        onClick={this.openConfirmationModal.bind(this)}
                    >
                        Attest to Users
                    </Button>
                </span>
                {isDisabled && (
                    <>
                        <br />
                        <span size="sm" className="attest-label">
                            In order to attest, you must search by a location and filter
                            by role type.
                        </span>
                    </>
                )}
                {cannotFMAttestToFMs && (
                    <>
                        <br />
                        <span size="sm" className="attest-label">
                            You cannot attest to other Facility Managers at this location.
                        </span>
                    </>
                )}
                {attestData.attested_at && (
                    <div className="attested-at">
                        Last Attested Date:{' '}
                        {moment(this.props.attestData.attested_at).format('M/D/YY')}
                        {moment(attestData.attested_at) >= ninetyDaysAgo && (
                            <Icons.IconCheckCircle size="md" />
                        )}
                        {moment(attestData.attested_at) < ninetyDaysAgo && (
                            <Icons.IconCloseCircle size="md" />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: attestFetchLoadingSelector(state),
    loadingPost: attestFetchLoadingSelector(state),
    attestData: attestFetchSelector(state),
    attestState: attestStateSelector(state),
    loggedInUser: state.login.user,
    userResults: state.fetchUsers?.userResults,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onAttest: attestUsers,
            fetchAttestation,
            fetchAttestationReset,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Attestation);
