/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { Badge, Heading, HelmetWrapperInternal, TYPES } from '@fp/sarsaparilla';
import axios from 'axios';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FEATURED_VERSION } from 'ui-navigation/src/constants/serviceNowAPIVersions';
import { getLocationDisplayName } from '../..';
import { getDomainsForLocation as getDomainsForLocationAction } from '../actions/fetchChildLocations';
import LocationPicker from '../components/LocationPicker';
import useDomainsForSelectedLocation from '../hooks/useDomainsForSelectedLocation';
import useLoggedInUser from '../hooks/useLoggedInUser';
import { launchBuckets } from '../utilities/launchTiles';
import { displayStringForRoleType } from '../utilities/roles';
import SiteWrapper from './SiteWrapper';
import Toast from '../components/Toast';

const propTypes = {
    history: TYPES.HISTORY,
    roleSelection: PropTypes.object,
    getDomainsForLocation: PropTypes.func,
    successForChange: PropTypes.bool,
};

export function LaunchPad({
    history,
    roleSelection,
    getDomainsForLocation,
    successForChange,
}) {
    const loggedInUser = useLoggedInUser();
    const [pendingEmailCount, setPendingEmailCount] = useState(0);
    const [showToast, setShowToast] = useState(isEqual(successForChange, true));

    useEffect(() => {
        getDomainsForLocation(roleSelection?.location, history);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        axios
            .get(
                `${process.env.SN_API}/${FEATURED_VERSION}/case/updates/${loggedInUser?.email}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                }
            )
            .then((response) => {
                setPendingEmailCount(response?.data?.result?.count ?? 0);
            })
            .catch(() => {});
    }, [loggedInUser]);

    const domainsAndTiles = useDomainsForSelectedLocation();

    function handleTitleClick(evt, path) {
        window.location.assign(path);
        evt.stopPropagation();
    }

    function handleLocationClick(location) {
        getDomainsForLocation(location, history);
    }

    function renderTiles(bucketName) {
        const bucketDomainsAndTiles = domainsAndTiles.filter(
            ({ tile }) => tile.bucket === bucketName
        );

        const tiles = bucketDomainsAndTiles.map(({ tile, path }, index) => (
            <button
                type="button"
                role="link"
                className="launch-tile wrapper"
                onClick={(e) => handleTitleClick(e, path, tile.leaf)}
                key={`Launch_Tile_${index}`}
            >
                <div className="rec-icon">{tile.icon}</div>
                <div className="rec-title">{tile.text}</div>
            </button>
        ));

        if (tiles.length === 0) {
            tiles.push(
                <button
                    type="button"
                    className="launch-tile wrapper"
                    key="Launch_Tile_Empty"
                >
                    <div className="rec-title">No Options Available at this Location</div>
                </button>
            );
        }

        return tiles;
    }

    function renderBuckets() {
        const buckets = Object.keys(launchBuckets);
        return buckets.map((name) => {
            return (
                <div
                    className={`launch-bucket launch-bucket-${name}`}
                    key={`tile_${name}`}
                >
                    <Heading
                        headingLevel={2}
                        appearance="h2"
                        className="launch-bucket-title"
                    >
                        {launchBuckets[name]}
                    </Heading>
                    <div className="launch-bucket-tiles">{renderTiles(name)}</div>
                </div>
            );
        });
    }

    function renderSelectedRole() {
        if (!roleSelection) {
            return null;
        }

        let roleType = displayStringForRoleType(roleSelection.role_type);

        if (!roleType) {
            roleType = 'N/A';
        }

        return (
            <div className="hero-selected-location-role-body">
                <div className="hero-selected-location-role-small">
                    You are currently viewing tiles as a
                </div>
                <div className="hero-selected-location-role-big">{roleType}</div>
                <div className="hero-selected-location-role-small">at</div>
                <div className="hero-selected-location-role-big">
                    {getLocationDisplayName(roleSelection.location)}
                </div>
            </div>
        );
    }

    const classes = classNames({
        'page-content': true,
        wrapper: true,
    });

    let contactUsLink;
    let trainingLinks;
    let viewCases;
    let viewTraining;

    if (!isEmpty(process.env.SN_CONTACT_INT_URL)) {
        contactUsLink = (
            <a
                href={process.env.SN_CONTACT_INT_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                Contact Us
            </a>
        );
    }

    if (!isEmpty(process.env.SN_KB_INT_URL)) {
        trainingLinks = (
            <a href={process.env.SN_KB_INT_URL} target="_blank" rel="noopener noreferrer">
                Help Center
            </a>
        );
    }

    if (!isEmpty(process.env.SN_CASES_URL)) {
        viewCases = (
            <a href={process.env.SN_CASES_URL} target="_blank" rel="noopener noreferrer">
                View My Cases
                {pendingEmailCount > 0 && (
                    <Badge
                        className="email-count-badge"
                        appearance="warning"
                        size="lg"
                        max={10}
                    >
                        {pendingEmailCount}
                    </Badge>
                )}
            </a>
        );
    }

    if (!isEmpty(process.env.SN_TRAINING_TASKS_URL)) {
        viewTraining = (
            <a
                href={process.env.SN_TRAINING_TASKS_URL}
                target="_blank"
                rel="noopener noreferrer"
            >
                View My Trainings
            </a>
        );
    }

    return (
        <>
            <HelmetWrapperInternal shouldUseTemplate={false} title="The Hub" />
            <Toast show={showToast} setShow={setShowToast}>
                <p className="sarsa-text size-lg">
                    <b>Email address successfully changed</b>
                    <br />
                    From now on, you can use your new address to Sign in.
                </p>
            </Toast>
            <SiteWrapper>
                <main className="hub-content wrapper">
                    <div className="hero-image head-ribbon">
                        <div className="hero-image-photo-wrapper fill">
                            <div className="hero-image-background-squares">
                                <div />
                                <div />
                                <div />
                                <div />
                            </div>
                        </div>
                        <Heading
                            headingLevel={1}
                            appearance="h1"
                            className="hero-image-title"
                        >
                            The Hub
                        </Heading>
                        {renderSelectedRole()}
                        <div className="hero-location-instructions">
                            CHANGE YOUR LOCATION:
                        </div>
                        <LocationPicker
                            filterID={'launch-pad'}
                            selectedLocation={roleSelection?.location}
                            locationSelect={handleLocationClick}
                        />
                        <div className="hero-image-links">
                            {contactUsLink}
                            {trainingLinks}
                            {viewCases}
                            {viewTraining}
                            <a
                                href={process.env.SN_SYSTEM_UPDATES_URL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                System Updates
                            </a>
                        </div>
                    </div>
                    <div id="LaunchPad" className={classes}>
                        <div className="launch-tiles wrapper">{renderBuckets()}</div>
                    </div>
                </main>
            </SiteWrapper>
        </>
    );
}

LaunchPad.propTypes = propTypes;

const mapStateToProps = (state) => ({
    roleSelection: state.selectedLocationRole.role,
    successForChange: state.confirmEmail.successForChange,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDomainsForLocation: getDomainsForLocationAction,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LaunchPad);
