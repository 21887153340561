/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    ButtonGroup,
    EmailField,
    emailValidator,
    FlexCol,
    FlexRow,
    HelmetWrapperInternal,
    Text,
    TextField,
    useFlags,
} from 'sarsaparilla';
import { loginMessagesStorageKey } from 'ui-internal-account/src/actions/login';
import {
    AvailableOidcProviders,
    oidcRedirect,
} from 'ui-internal-account/src/actions/oidc';
import useOidcConfig from 'ui-internal-account/src/hooks/useOidcConfig';
import { errorForLogin } from 'ui-internal-account/src/utilities/errorMessages';

function LoginFormHeader({
    title,
    info,
    showLoginForm,
}: {
    title: string;
    info: string;
    showLoginForm: boolean;
}) {
    return (
        <>
            <h1>{title}</h1>
            {!showLoginForm && (
                <Text fontWeight="bold">
                    Select the login method recommended by your Agency to access the{' '}
                    <a href="/internal/account/hub">{process.env.SITE_NAME} Hub.</a>
                </Text>
            )}
            {info && <Alert type="info">{info}</Alert>}
        </>
    );
}

type LoginFormProps = {
    loginFn: (email: string, password: string) => void;
    initialEmail: string;
    setEmailFn: (email: string) => void;
    errorMessage: string;
    attempts: number;
};

type ErrorMessageProps = {
    errorMessage: string;
    concurrentSessionError: boolean;
    attempts: number;
};

function ErrorMessage({
    errorMessage,
    concurrentSessionError,
    attempts,
}: ErrorMessageProps) {
    if (errorMessage || concurrentSessionError) {
        let err = errorForLogin(errorMessage, attempts);

        if (concurrentSessionError) {
            err =
                'You have been logged out of your current session because this account is logged in with another browser. ' +
                'Please sign in again to gain access to your account and force a logout of the other session.';
        }

        return (
            <Alert shouldFocusOnMount type="error">
                {err}
            </Alert>
        );
    }

    return null;
}

export function LoginForm({
    loginFn,
    initialEmail,
    errorMessage,
    setEmailFn,
    attempts,
}: LoginFormProps) {
    const { iaEnableMfa, enableOidc } = useFlags();

    const [email, setEmailField] = useState(initialEmail);
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [password, setPassword] = useState('');
    const [loginMessageFlags, setLoginMessageFlags] = useState({
        concurrentSessionError: false,
        loggedOutForInactivity: false,
    });
    const [showLoginForm, setShowLoginForm] = useState(false);
    const oidcCfg = useOidcConfig();

    useEffect(() => {
        setShowLoginForm(!enableOidc);

        const loginMessagesString = localStorage.getItem(loginMessagesStorageKey);
        if (loginMessagesString) {
            setLoginMessageFlags(JSON.parse(loginMessagesString));
        }
    }, [enableOidc]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailField(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!emailValidator(email).isValid) {
            setEmailIsValid(false);
            return;
        }

        setEmailIsValid(true);
        loginFn(email, password);
    };

    const setEmail = () => {
        setEmailFn(email);
    };

    const renderLoginForm = () => {
        return (
            <div className="login-form-container">
                {!emailIsValid && (
                    <Alert type="error" className="mb-2">
                        That is not a valid email address.
                    </Alert>
                )}
                <div>
                    <EmailField
                        id="email"
                        onChange={handleEmailChange}
                        value={email}
                        aria-label="Email Address"
                        placeholder="Email Address"
                    />
                </div>
                <div>
                    <TextField
                        label=""
                        type="password"
                        id="password"
                        onChange={handlePasswordChange}
                        value={password}
                        aria-label="Password"
                        placeholder="Password"
                        enableShowPassword
                    />
                </div>
                <ErrorMessage
                    attempts={attempts}
                    errorMessage={errorMessage}
                    concurrentSessionError={loginMessageFlags?.concurrentSessionError}
                />
                <ButtonGroup isStacked>
                    <Button type="submit" id="login">
                        Login
                    </Button>
                    {enableOidc && (
                        <Button
                            onClick={() => {
                                setShowLoginForm(false);
                            }}
                            appearance="tertiary"
                            type="button"
                            id="login-methods"
                        >
                            Return to Sign In Options
                        </Button>
                    )}
                </ButtonGroup>
                <div>
                    <Link
                        id="forgot-password"
                        to="/internal/account/recover-password"
                        onClick={setEmail}
                    >
                        Forgot Password?
                    </Link>
                </div>
            </div>
        );
    };

    const renderLoginMethods = () => {
        return (
            <div>
                <FlexRow>
                    <FlexCol className="mt-3">
                        <ButtonGroup isStretchedToFit>
                            <Button
                                onClick={() => {
                                    setShowLoginForm(true);
                                }}
                                appearance="tertiary"
                                type="button"
                                id="hub-method"
                            >
                                Use Hub Login
                            </Button>
                        </ButtonGroup>
                    </FlexCol>
                    <FlexCol className="mt-3">
                        <ButtonGroup isStretchedToFit>
                            <Button
                                onClick={() => {
                                    setShowLoginForm(false);
                                    oidcRedirect(
                                        oidcCfg,
                                        AvailableOidcProviders.loginDotGov
                                    );
                                }}
                                appearance="tertiary"
                                type="button"
                                id="oidc-method"
                            >
                                Use Login.gov
                            </Button>
                        </ButtonGroup>
                    </FlexCol>
                </FlexRow>
                <FlexRow>
                    <FlexCol className="mt-3 mb-3">
                        <Text className="left">
                            Use your Hub account email
                            {iaEnableMfa
                                ? ', password, and an MFA TOTP device (e.g. Google authentication, MS authenticator, Duo)'
                                : ' and password'}{' '}
                            to sign in.
                        </Text>
                    </FlexCol>
                    <FlexCol className="mt-3 mb-3">
                        <Text className="left">
                            Use the same username and password to log into all
                            participating .gov websites. You will need your{' '}
                            <a href="https://login.gov">Login.gov</a> account email,
                            password, and a secondary authentication method; PIV, CAC,
                            LincPass, or secure key device to sign in.
                        </Text>
                    </FlexCol>
                </FlexRow>
            </div>
        );
    };

    let title = 'Sign In';
    let info = '';
    if (loginMessageFlags.loggedOutForInactivity) {
        title = 'Session Timeout';
        info =
            'You have been logged out automatically due to inactivity. Login with your email and password.';
    }

    return (
        <form onSubmit={onLogin}>
            <div className="login-container">
                <HelmetWrapperInternal title={title} />
                <LoginFormHeader
                    title={title}
                    info={info}
                    showLoginForm={showLoginForm}
                />
                {showLoginForm ? renderLoginForm() : renderLoginMethods()}
            </div>
        </form>
    );
}
