/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu } from 'sarsaparilla';
import TextHighlight from '../tableUtil/TextHighlight';

const propTypes = {
    id: PropTypes.string,
    permits: PropTypes.array,
    searchText: PropTypes.string,
};

export default function AllAgenciesDropdown({ id, permits, searchText }) {
    const locationsDeduped = [...new Set(permits.map((permit) => permit.location_name))];
    const locations = locationsDeduped
        .filter((val) => val.length > 0)
        .map((permit) => ({
            sort: permit.toLowerCase(),
            display: permit,
        }));
    locations.sort((a, b) => a.sort.localeCompare(b.sort));
    let matchedLocations = null;
    const globalFinded = searchText?.length > 0;
    if (globalFinded) {
        matchedLocations = locations.filter((permit) => permit.sort.includes(searchText));
    }

    if (locations.length === 0) {
        return null;
    }

    const text = `${locations.length} Location${locations.length > 1 ? 's' : ' '}`;

    return (
        <DropdownMenu
            id={id}
            className="ia-all-agencies-dropdown"
            badgeCount={matchedLocations?.length}
            showPopupActions={false}
            label={text}
        >
            <ul>
                {locations.map((location, index) => (
                    <li key={`location-dropdown-${index}`}>
                        <TextHighlight searchTerm={searchText} body={location.display} />
                    </li>
                ))}
            </ul>
        </DropdownMenu>
    );
}

AllAgenciesDropdown.propTypes = propTypes;
