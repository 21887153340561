/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import memoize from 'memoize-one';
import { Button, Icons, TextFieldStateless, Spinner } from 'sarsaparilla';

const propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    onExpand: PropTypes.func,
    apiSearchTerm: PropTypes.string,
    loading: PropTypes.bool,
};

const globalFilterFunc = memoize((rows, columnIds, globalFilterValue) => {
    if (globalFilterValue) {
        return rows.filter(
            (row) =>
                row.depth !== globalFilterValue.depth + 1 ||
                columnIds.some((columnId) => {
                    return row.values[columnId]
                        ?.toLowerCase()
                        .includes(globalFilterValue.value.toLowerCase());
                })
        );
    }
    return rows;
});

export default function UserLocationTable({
    columns,
    data,
    onExpand,
    apiSearchTerm,
    loading,
}) {
    const renderExpansion = (row, toggleRowExpanded, locExpanded) => {
        if (!row.canExpand) {
            return <span />;
        }
        return (
            <Button
                size="sm"
                iconBeforeElement={
                    row.isExpanded ? <Icons.IconArrowDown /> : <Icons.IconArrowRight />
                }
                onClick={() => {
                    if (!row.isExpanded) {
                        onExpand(row.original);
                    } else {
                        //Close all child rows that are expanded.
                        const childRows = Object.keys(locExpanded).filter(
                            (id) => id !== row.id && id.startsWith(row.id)
                        );
                        childRows.forEach(toggleRowExpanded);
                    }
                    row.toggleRowExpanded();
                }}
                screenReaderTextBefore={`Expand ${row.id}`}
                appearance={row.isExpanded ? 'icon-white' : 'subtle'}
            />
        );
    };

    const renderSpan = () => {
        return <span />;
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        toggleAllRowsExpanded,
        state: { expanded, globalFilter },
    } = useTable(
        {
            columns,
            data,
            globalFilter: globalFilterFunc,
            getSubRows: (row) => row.children || [],
            autoResetExpanded: false,
        },
        useGlobalFilter,
        useExpanded,
        (hooks) => {
            hooks.visibleColumns.push((existingColumns) => [
                {
                    id: 'expansion',
                    Header: () => renderSpan(),
                    disableGlobalFilter: true,
                    disableSortBy: true,
                    maxWidth: 45,
                    Cell: ({
                        row,
                        toggleRowExpanded,
                        state: { expanded: locExpanded },
                    }) => renderExpansion(row, toggleRowExpanded, locExpanded),
                },
                ...existingColumns,
            ]);
        }
    );

    React.useEffect(() => {
        toggleAllRowsExpanded(false);
        setGlobalFilter(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const expandedLevel = React.useMemo(() => {
        const levels = Object.keys(expanded).map((value) => value.split('.').length);
        return levels.length > 0 ? Math.max(...levels) : 0;
    }, [expanded]);

    const getWidthStyle = ({ width, maxWidth, minWidth }) => ({
        style: {
            width: `${width}px`,
            maxWidth: `${maxWidth}px`,
            minWidth: `${minWidth}px`,
        },
    });

    return (
        <div className="shared-ui-react-table-with-layout rec-ia-table">
            <div className="rt-table ia-user-location-select" {...getTableProps()}>
                <div className="rt-thead">
                    {headerGroups.map((headerGroup) => (
                        <div className="rt-tr" {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((header) => (
                                <div
                                    className="rt-th"
                                    {...header.getHeaderProps(getWidthStyle(header))}
                                >
                                    {header.render('Header')}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="rt-tbody" {...getTableBodyProps()}>
                    {loading ? (
                        <Spinner />
                    ) : (
                        rows.map((row) => {
                            prepareRow(row);
                            if (row.original.location_name === 'No Results Found') {
                                return (
                                    <div className="ia-table-row" {...row.getRowProps()}>
                                        <div className="rt-tr">
                                            <div className="ia-basic-table-no-data">
                                                No results for {apiSearchTerm}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (
                                row.original.location_name ===
                                'Error Occurred Loading Data'
                            ) {
                                return (
                                    <div className="ia-table-row" {...row.getRowProps()}>
                                        <div className="rt-tr">
                                            <div className="ia-basic-table-no-data error">
                                                An error occurred loading data.
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (row.original.location_name === 'Loading') {
                                return (
                                    <div className="ia-table-row" {...row.getRowProps()}>
                                        <div className="rt-tr">
                                            <Spinner />
                                        </div>
                                    </div>
                                );
                            }
                            return row.depth >= expandedLevel || row.isExpanded ? (
                                <div className="ia-table-row" {...row.getRowProps()}>
                                    <div
                                        className={`rt-tr${row.isExpanded ? ' expanded-row' : ''}`}
                                    >
                                        {row.cells.map((cell) => (
                                            <div
                                                className="rt-td"
                                                {...cell.getCellProps(
                                                    getWidthStyle(cell.column)
                                                )}
                                            >
                                                {cell.render('Cell')}
                                            </div>
                                        ))}
                                    </div>
                                    {row.isExpanded &&
                                        row.depth + 1 === expandedLevel && (
                                            <TextFieldStateless
                                                id="filter-text-field"
                                                isLabelVisible={false}
                                                label="Filter locations"
                                                hasClearButton
                                                handleClearButtonClick={() =>
                                                    setGlobalFilter(null)
                                                }
                                                iconElement={<Icons.IconSearch />}
                                                placeholder="Filter locations"
                                                value={globalFilter?.value || ''}
                                                onChange={({ target: { value } }) => {
                                                    setGlobalFilter(
                                                        { depth: row.depth, value } ||
                                                            null
                                                    );
                                                }}
                                            />
                                        )}
                                </div>
                            ) : null;
                        })
                    )}
                </div>
            </div>
        </div>
    );
}

UserLocationTable.propTypes = propTypes;
