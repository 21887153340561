/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    IncompatibleBrowserNotice,
    TYPES,
    getSanitizedRedirectUrl,
    useFlags,
} from 'sarsaparilla';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HubLoginTermsOfService } from 'site-kit';
import { LoginForm } from 'ui-internal-account/src/components/login/loginForm';
import MfaLoginPage from 'ui-internal-account/src/containers/MfaLoginPage';
import { checkLoggedIn, logIn, setEmail } from '../actions/login';
import ConfirmEmailAlert from '../components/ConfirmEmailAlert';
import { isLoggedIn } from '../utilities/internalSecurityUtil';
import Loading from './Loading';

const propTypes = {
    history: TYPES.HISTORY,
    location: TYPES.LOCATION,
    data: PropTypes.shape({
        email: PropTypes.string,
        password: PropTypes.string,
    }),
    checkLoggedIn: PropTypes.func,
    logIn: PropTypes.func,
    setEmail: PropTypes.func,
    confirmedEmail: PropTypes.string,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            message: PropTypes.string,
        }),
    ]),
    attempts: PropTypes.number,
    query: PropTypes.shape({
        redirect: PropTypes.string,
        token: PropTypes.string,
    }),
};

// Maybe this is dead code
export class LegacyLoginPage extends React.Component {
    static propTypes = propTypes;

    constructor(props) {
        super(props);

        this.state = {
            email: props.data ? props.data.email : '',
            showConfirmEmailChange: !isEmpty(props.query?.token) && isEmpty(props.error),
            newConfirmFlow:
                props.location?.query?.redirect?.includes('confirm-email-change'),
        };

        this.toggleShowConfirmEmailChange = this.toggleShowConfirmEmailChange.bind(this);
        this.logIn.bind(this);
        this.setEmail.bind(this);
    }

    componentDidMount() {
        this.props.checkLoggedIn(null, this.props.history);

        if (isLoggedIn()) {
            this.props.history.push('/internal/account/hub');
        }

        if (this.props.location?.query?.email) {
            const email = decodeURIComponent(this.props.location?.query?.email);
            this.setState({ email });
        }

        if (this.props.confirmedEmail) {
            this.toggleShowConfirmEmailChange();
        }
    }

    setEmail = () => {
        this.props.setEmail(this.state.email);
    };

    logIn = (email, pwd) => {
        const redirect = getSanitizedRedirectUrl() ?? this.props.query?.redirect;
        this.props.logIn(
            email,
            pwd,
            isEmpty(this.props.query?.token)
                ? redirect
                : `${redirect}?token=${this.props.query?.token}`,
            this.props.history
        );
    };

    toggleShowConfirmEmailChange() {
        this.setState((prevState) => ({
            showConfirmEmailChange: !prevState.showConfirmEmailChange,
        }));
    }

    render() {
        return (
            <div>
                <ConfirmEmailAlert
                    newConfirmFlow={this.state.newConfirmFlow}
                    show={this.state.showConfirmEmailChange}
                    setShow={this.toggleShowConfirmEmailChange}
                />

                <div className="logged-out-container">
                    <Loading />
                    <LoginForm
                        loginFn={this.logIn}
                        setEmailFn={this.setEmail}
                        initialEmail={this.state.email}
                        errorMessage={this.props.error}
                        attempts={this.props.attempts}
                    />
                </div>
                <HubLoginTermsOfService />
                <IncompatibleBrowserNotice />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.login,
        confirmedEmail: state.confirmEmail.successFor,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            checkLoggedIn,
            logIn,
            setEmail,
        },
        dispatch
    );

const LegacyLoginPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LegacyLoginPage);

function LoginPageWrapper(props) {
    const { iaEnableMfa } = useFlags();
    if (iaEnableMfa !== false) {
        return <MfaLoginPage query={props?.location?.query} />;
    }

    return (
        <LegacyLoginPageContainer
            history={props.history}
            location={props.location}
            data={props.data}
            checkLoggedIn={props.checkLoggedIn}
            logIn={props.logIn}
            setEmail={props.setEmail}
            confirmedEmail={props.confirmedEmail}
            error={props.error}
            attempts={props.attempts}
            query={props?.location?.query}
        />
    );
}

LoginPageWrapper.propTypes = {
    ...propTypes,
    location: PropTypes.shape({
        query: PropTypes.shape({
            redirect: PropTypes.string,
            token: PropTypes.string,
        }),
    }),
};

export default LoginPageWrapper;
