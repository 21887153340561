/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as SharedConstants from './shared-constants';
import * as issuanceConstants from '../constants/issuanceStatuses';

export * from '../constants/issuanceStatuses';

export const LOTTERY_TYPES = {
    Seasonal: 'Seasonal',
    Daily: 'Daily',
};

export const SEASON_TYPES = {
    Lottery: 'Lottery',
    High: 'High',
};

export const DIVISION_STATUS = {
    CLOSED: 0,
    OPEN: 1,
};

export const DATE_FORMAT = 'ddd, MMM D, YYYY';

export const RES_ID_DELIMITER = '__';

export const DATE_TIME_FORMAT = 'ddd, MMM D, YYYY | hh:mmA z';
export const TIME_FORMAT = 'hh:mmA z';

export const DATE_TITLE_FORMAT = 'MMM D, YYYY';

export const ISSUANCE_STATUS = new Map();
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_PRELIMINARY, 0);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_IN_PROGRESS, 1);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_IN_PROGRESS, 2);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_APPLIED, 3);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_AWARDED, 4);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_AWARDED_IN_PROGRESS, 5);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_LOST, 6);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_DECLINED, 7);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_ACCEPTED, 8);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_NO_RESPONSE, 9);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_COMPLETE, 10);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_ISSUED, 11);
ISSUANCE_STATUS.set(issuanceConstants.CANCELLED_NO_RELEASE, 12);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_CANCELLED_BY_USER, 13);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_CANCELLED, 14);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_REFUNDED, 15);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_VOIDED, 16);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_DISQUALIFIED, 17);
ISSUANCE_STATUS.set(issuanceConstants.PERMIT_NO_SHOW, 18);
ISSUANCE_STATUS.set(issuanceConstants.LOTTERY_PENDING, 19);

export const AVAILABILITY_KEY_MAP_FORMAT = 'YYYY-MM-DD';

export const GROUP_AVAILABILITY_DAILY_KEY_NAME = 'ConstantQuotaUsageDaily';
export const GROUP_AVAILABILITY_KEY_NAME = 'ConstantQuotaUsageByStartDate';
export const MEMBER_AVAILABILITY_KEY_NAME = 'QuotaUsageByMember';
export const MEMBER_AVAILABILITY_DAILY_KEY_NAME = 'QuotaUsageByMemberDaily';

export const DAY_BLOCKED_DATE_IN_THE_PAST = 'DAY_BLOCKED_DATE_IN_THE_PAST';
export const DAY_BLOCKED_OUTSIDE_SEASON = 'DAY_BLOCKED_OUTSIDE_SEASON';
export const DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON = 'DAY_BLOCKED_OUTSIDE_LOTTERY_SEASON';
export const DAY_BLOCKED_BY_LOTTERY = 'DAY_BLOCKED_BY_LOTTERY';
export const DAY_BLOCKED_MISSING_QUOTA = 'DAY_BLOCKED_MISSING_QUOTA';
export const DAY_BLOCKED_NO_QUOTA = 'DAY_BLOCKED_NO_QUOTA';
export const DAY_UNBLOCKED = 'DAY_UNBLOCKED';

export const FILTER_NUMERICAL = 'numerical';

export const AVAILABILITY_FILTERS = () => {
    return [
        {
            type: SharedConstants.GROUP_AVAILABILITY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: 'Daily Groups',
            labelAlt: 'Daily Groups',
            initialValue: 1,
            minValue: 1,
            maxValue: 1,
            showStandalone: false,
            doNotRender: true,
        },
        {
            type: SharedConstants.MEMBER_AVAILABILITY_KEY_NAME,
            display: SharedConstants.FILTER_NUMERICAL,
            label: 'People',
            labelAlt: 'Group Members',
            initialValue: 0,
            missingInputError: 'Please enter the number of group members',
            minValue: 1,
            showStandalone: true,
        },
    ];
};

export const COMMERCIAL_GUIDES = [
    'NCOAE',
    "Bischof's Flyfishing",
    'Bend Parks & Recreation District',
    'City of Eugene River House Outdoor Center',
    'Arrowhead River Adventures',
    'Mah-Hah Outfitters',
    'Deschutes River Anglers',
    "Young Life's Washington Family Ranch",
    'Alex Gonsiewski LLC',
    'A Helfrich Outfitter',
    "Chris O'Donnell's Guide Service",
    'Little Creek Outfitters',
    'Steelhead Outfitters',
    'Steve Smith Outdoors',
    'Oregon River Experiences',
    'Ouzel Outfitters',
    'Service Creek Rafting',
];

export const BEAUTIFY_ENTRY_TYPES = {
    email: 'email address',
    cell_phone: 'phone',
};

export const SINGLE_DATE_PICKER_TYPE = 'single-date';
export const RANGE_DATE_PICKER_TYPE = 'range-date';

export const RANGE_START_DATE_PATH = 'preferred_range_start';
export const RANGE_END_DATE_PATH = 'preferred_range_end';

export const ENTRY_DATE_PATH = 'preferred_date';
