/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import {
    Select,
    Alert,
    RequiredValidation,
    EmailValidation,
    FlexRow,
    FlexCol,
    Heading,
    Button,
} from 'sarsaparilla';

import PropTypes from 'prop-types';
import { sendFeedback } from '../../actions/contactActions';
import contactDropdown from '../../constants/contactDropdown';
import agencyDropdown from '../../constants/agencyDropdown';
import satelliteComments from '../../constants/satelliteComments';

const SATELLITE = 'SATELLITE';

class ContactUs extends React.Component {
    static propTypes = {
        sendFeedback: PropTypes.func,
        sendSuccess: PropTypes.bool,
        sendErr: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            facility: '',
            facilityID: '',
            comments: '',
            topic: 'ACC_PASS_RESET',
            agency: 'Forest Service',
        };

        this.changeAgency = this.changeAgency.bind(this);
        this.changeTopic = this.changeTopic.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onFirstNameValidate = this.onFirstNameValidate.bind(this);
        this.onLastNameValidate = this.onLastNameValidate.bind(this);
        this.onEmailValidate = this.onEmailValidate.bind(this);
        this.onFacilityValidate = this.onFacilityValidate.bind(this);
        this.onPhoneValidate = this.onPhoneValidate.bind(this);
        this.onCommentsValidate = this.onCommentsValidate.bind(this);
    }

    onFirstNameValidate(error) {
        this.setState({ firstNameErr: error });
    }

    onLastNameValidate(error) {
        this.setState({ lastNameErr: error });
    }

    onEmailValidate(error) {
        this.setState({ emailErr: error });
    }

    onFacilityValidate(error) {
        this.setState({ facErr: error });
    }

    onPhoneValidate(error) {
        this.setState({ phoneErr: error });
    }

    onCommentsValidate(error) {
        this.setState({ commentsErr: error });
    }

    onChange(e) {
        const target = e.target;
        this.setState({ [target.name]: target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({ sendAttempt: true });

        this.props.sendFeedback({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            phone: this.state.phone,
            topic: this.state.topic,
            comments: this.state.comments,
            agency: this.state.agency,
            facility: this.state.facility,
            facilityID: this.state.facilityID,
            email: this.state.email,
            caseType: 'Internal',
        });
    }

    changeAgency(selection) {
        this.setState({ agency: selection.target.value });
    }

    changeTopic(selection) {
        if (selection.target.value === SATELLITE) {
            this.setState(
                { comments: satelliteComments },
                this.setState({ topic: selection.target.value })
            );
        }
        this.setState({ topic: selection.target.value });
    }

    renderSatelliteComments() {
        if (this.state.topic === SATELLITE) {
            return (
                <FlexCol xs={12}>
                    <div className="rec-form-item-wrap">
                        <label htmlFor="comments">
                            Comments
                            <mark title="required">*</mark>
                        </label>
                        <textarea
                            value={this.state.comments}
                            disabled={this.props.sendSuccess}
                            id="comments"
                            name="comments"
                            type="textarea"
                            rows="8"
                            placeholder="How can we help you?"
                            onChange={this.onChange}
                        />
                    </div>
                </FlexCol>
            );
        }
        return (
            <FlexCol xs={12}>
                <div className="rec-form-item-wrap">
                    <label htmlFor="comments">
                        Comments
                        <mark title="required">*</mark>
                    </label>
                    <textarea
                        value={this.state.comments}
                        disabled={this.props.sendSuccess}
                        id="comments"
                        name="comments"
                        type="textarea"
                        rows="8"
                        placeholder="How can we help you?"
                        onChange={this.onChange}
                    />
                    <RequiredValidation
                        value={this.state.comments}
                        controlLabel="Comments"
                        onValidated={this.onCommentsValidate}
                    />
                </div>
            </FlexCol>
        );
    }

    render() {
        let showSuccessClass = '';
        if (!this.props.sendSuccess) {
            showSuccessClass = 'hide';
        }

        let showErrorClass = '';
        if (!this.props.sendErr) {
            showErrorClass = 'hide';
        }

        return (
            <section>
                <Heading headingLevel={2} appearance="h3" className="mb-2">
                    Email Us
                </Heading>

                <form className="ia-contact-us-form-wrap" onSubmit={this.onSubmit}>
                    <FlexRow>
                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="first-name">
                                    First Name
                                    <mark title="required">*</mark>
                                </label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="first-name"
                                    name="firstName"
                                    type="text"
                                    placeholder="Jane"
                                    onChange={this.onChange}
                                />
                                <RequiredValidation
                                    value={this.state.firstName}
                                    controlLabel="First Name"
                                    onValidated={this.onFirstNameValidate}
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="last-name">
                                    Last Name
                                    <mark title="required">*</mark>
                                </label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="last-name"
                                    name="lastName"
                                    type="text"
                                    placeholder="Doe"
                                    onChange={this.onChange}
                                />
                                <RequiredValidation
                                    value={this.state.lastName}
                                    controlLabel="Last Name"
                                    onValidated={this.onLastNameValidate}
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="phone-number">
                                    Phone Number
                                    <mark title="required">*</mark>
                                </label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="phone-number"
                                    name="phone"
                                    placeholder="(123) 234-5678 extension 1234"
                                    onChange={this.onChange}
                                />
                                <RequiredValidation
                                    value={this.state.phone}
                                    controlLabel="Phone Number"
                                    onValidated={this.onPhoneValidate}
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="Email">
                                    Email
                                    <mark title="required">*</mark>
                                </label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="email@domain"
                                    onChange={this.onChange}
                                />
                                <RequiredValidation
                                    value={this.state.email}
                                    controlLabel="Email"
                                    onValidated={this.onEmailValidate}
                                />
                                <EmailValidation
                                    value={this.state.email}
                                    onValidated={this.onEmailValidate}
                                    controlLabel="Email"
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <Select
                                    isDisabled={this.props.sendSuccess}
                                    label="Agency"
                                    id="agency-dropdown"
                                    options={agencyDropdown}
                                    onChange={this.changeAgency}
                                    value={this.state.agency}
                                    isRequired
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="facility-name">
                                    Facility Name
                                    <mark title="required">*</mark>
                                </label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="facility-name"
                                    type="text"
                                    name="facility"
                                    placeholder="Facility Name"
                                    onChange={this.onChange}
                                />
                                <RequiredValidation
                                    value={this.state.facility}
                                    controlLabel="Facility Name"
                                    onValidated={this.onFacilityValidate}
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <label htmlFor="facility-ID">Facility ID</label>
                                <input
                                    disabled={this.props.sendSuccess}
                                    id="facility-ID"
                                    name="facilityID"
                                    type="text"
                                    placeholder="Facility ID"
                                    onChange={this.onChange}
                                />
                            </div>
                        </FlexCol>

                        <FlexCol md={6}>
                            <div className="rec-form-item-wrap">
                                <Select
                                    isDisabled={this.props.sendSuccess}
                                    label="Topic"
                                    id="topic-dropdown"
                                    options={contactDropdown}
                                    onChange={this.changeTopic}
                                    value={this.state.topic}
                                    isRequired
                                />
                            </div>
                        </FlexCol>

                        {this.renderSatelliteComments()}

                        <FlexCol xs={12} className={showSuccessClass}>
                            <Alert type="success" className="mb-3">
                                <p>Thanks for your feedback!</p>
                            </Alert>
                        </FlexCol>

                        <FlexCol xs={12} className={showErrorClass}>
                            <Alert type="error" className="mb-3">
                                <p>Feedback submission failed.</p>
                            </Alert>
                        </FlexCol>

                        <FlexCol className="ia-contact-submit-btn-wrap">
                            <Button
                                type="submit"
                                disabled={
                                    this.state.sendAttempt ||
                                    this.props.sendSuccess ||
                                    this.state.firstNameErr ||
                                    this.state.lastNameErr ||
                                    this.state.emailErr ||
                                    this.state.facErr ||
                                    this.state.phoneErr ||
                                    this.state.commentsErr
                                }
                            >
                                Submit
                            </Button>
                        </FlexCol>
                    </FlexRow>
                </form>
            </section>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendFeedback: (feedback) => dispatch(sendFeedback(feedback)),
    };
}

export default connect(
    (state) => ({
        sendErr: state.contact.sendErr,
        sendSuccess: state.contact.sendSuccess,
    }),
    mapDispatchToProps
)(ContactUs);
