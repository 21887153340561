/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import axios, { AxiosHeaders } from 'axios';
import { Dispatch } from 'redux';
import { HistoryV4 } from 'shared-ui';
import * as globals from '../constants/globals';
import { OidcConfig } from '../hooks/useOidcConfig';
import { doneLoading, startLoading } from './loading';
import {
    clearError,
    JSONContentType,
    loggedIn,
    loginFailed,
    navigateLogin,
    resetIdleMonitor,
} from './login';

export const AvailableOidcProvidersLabels = Object.freeze({
    loginDotGov: 'Login.gov',
});

export const AvailableOidcProviders = Object.freeze({
    loginDotGov: 'loginDotGov',
});

export const oidcRedirect = (config: OidcConfig, provider: string) => {
    switch (provider) {
        case AvailableOidcProviders.loginDotGov: {
            const oidcState = `${config.state}:${AvailableOidcProviders.loginDotGov}`;
            const params: string[] = [
                `acr_values=${config.acrValues}&`,
                `client_id=${config.clientID}&`,
                `nonce=${config.nonce}&`,
                `prompt=${config.prompt}&`,
                `redirect_uri=${config.redirectUri}&`,
                `response_type=${config.responseType}&`,
                `scope=${config.scope}&`,
                `state=${oidcState}&`,
            ];

            localStorage.removeItem(globals.OIDC_STATE_STORAGE_KEY);
            localStorage.setItem(globals.OIDC_STATE_STORAGE_KEY, oidcState);

            const url = `${config.authorizeUrl}?${params.join('')}`;
            window.location.assign(url);
            break;
        }
        default:
            throw new Error('invalid oidc tenant provided');
    }
};

export const authorizeCallback = (code: string, state: string, history: HistoryV4) => {
    return async (dispatch: Dispatch<any>) => {
        const pieces = decodeURI(state).split(':');
        if (pieces?.length !== 2) {
            dispatch(loginFailed('no oidc provider found in callback'));
            navigateLogin();
            return;
        }

        const provider = pieces[1];
        const postData = { code, state, provider };

        try {
            dispatch(startLoading('Signing In...'));
            resetIdleMonitor();

            const headers = new AxiosHeaders();
            headers.concat('Content-Type', JSONContentType);

            const response = await axios.post(
                `${globals.API_URL}/oidc/authorization/callback`,
                postData,
                { headers }
            );

            const loginResponseData = response?.data;
            if (response.status >= 200 && response.status < 300) {
                dispatch(clearError());
                dispatch(loggedIn(loginResponseData, null, history));
            } else {
                throw new Error(loginResponseData.error);
            }
        } catch (error) {
            dispatch(loginFailed(error));
            navigateLogin();
        } finally {
            dispatch(doneLoading());
        }
    };
};
