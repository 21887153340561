/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useFlags } from 'sarsaparilla';

const propTypes = {
    user: PropTypes.shape({
        should_receive_sms: PropTypes.bool,
        locked: PropTypes.bool,
        is_email_confirmed: PropTypes.bool,
        user_id: PropTypes.string,
        last_logged_in_at: PropTypes.string,
        must_set_password: PropTypes.bool,
        password_set_at: PropTypes.string,
        last_attested_at: PropTypes.string,
        created_at: PropTypes.string,
        enforce_effective_dates: PropTypes.bool,
        effective_start_at: PropTypes.string,
        effective_end_at: PropTypes.string,
    }),
};

export default function AdditionalInformation({ user }) {
    const { enableIaEffectiveDates } = useFlags();

    if (!user) {
        return null;
    }

    const formatTimes = (value) => {
        const dateFormat = 'MMM Do, YYYY';
        let valueInTime = 'never';
        let valueInTimeAgo = '';
        if (value) {
            const m = moment(value);
            valueInTime = m.format(dateFormat);
            valueInTimeAgo = `(${m.fromNow()})`;
        }
        return { valueInTime, valueInTimeAgo };
    };

    const renderRow = (label, timestamp) => {
        const { valueInTime, valueInTimeAgo } = formatTimes(timestamp);
        return (
            <li>
                {label}: {valueInTime} {valueInTimeAgo}
            </li>
        );
    };

    const showEffectiveDates = enableIaEffectiveDates && user.enforce_effective_dates;
    const accountInactive = moment().isBefore(user?.effective_start_at);
    const accountExpired = moment().isAfter(user?.effective_end_at);

    return (
        <div className="update-user-options">
            <h6>History</h6>
            <div className="update-user-options-controls">
                <ul>
                    {renderRow('Created at', user.created_at)}
                    {renderRow('Last Logged in', user.last_logged_in_at)}
                    {renderRow('Password last set', user.password_set_at)}
                    {renderRow('Last attested to', user.last_attested_at)}
                    {user.must_set_password && <li>User&apos;s password has expired</li>}
                    {!user.is_email_confirmed && (
                        <li>User&apos;s email has not been confirmed</li>
                    )}
                    {showEffectiveDates && accountInactive && (
                        <li>
                            Account not active until{' '}
                            {moment(user?.effective_start_at).format('MM/DD/YYYY')}
                        </li>
                    )}
                    {showEffectiveDates && accountExpired && (
                        <li>
                            Account expired on{' '}
                            {moment(user?.effective_end_at).format('MM/DD/YYYY')}
                        </li>
                    )}
                    {user.should_receive_sms && <li>Opted-in to text messages</li>}
                </ul>
            </div>
        </div>
    );
}

AdditionalInformation.propTypes = propTypes;
