/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    Box,
    Button,
    ButtonGroup,
    FlexCol,
    ModalActions,
    Select,
    Spacer,
    StyledModal,
    Text,
} from '@fp/sarsaparilla';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createRangerAppToken } from '../../actions/rangerAppToken';
import {
    computeLocationStateCode,
    displayStringForLocationType,
    getLocationDisplayName,
} from '../../utilities/locations';
import { ATTENDANT, HOST, roleDropdownOptionsForLocation } from '../../utilities/roles';
import LocationPicker from '../LocationPicker';
import SimpleTable from '../tableUtil/SimpleTable';
import DisplayRoleLocation from './DisplayRoleLocation';

function renderRoles(row, value, location, roles, onChange) {
    const options = roleDropdownOptionsForLocation(roles, location, false).filter(
        (option) => option.value === HOST || option.value === ATTENDANT
    );

    const id = `edit-role-selector-${row.original.location_id}`;

    return (
        <div className={'ia-role-select'}>
            <Select
                id={id}
                label="Select role"
                isLabelVisible={false}
                disabled={false}
                tabIndex={0}
                options={options}
                onChange={onChange}
                placeholder="Select Role..."
                value={value?.role_type}
            />
        </div>
    );
}

function renderActions(row, onRemove) {
    return (
        <ButtonGroup isStretchedToFit>
            <Button
                className="remove-location"
                appearance="link"
                onClick={() => onRemove(row.original)}
            >
                Remove
            </Button>
        </ButtonGroup>
    );
}

function RoleLocationTable({
    roleLocationMap,
    setRoleLocationMap,
    selectedLocations,
    location,
    roles,
    deleteLocation,
}) {
    const columns = React.useMemo(() => {
        const cols = [
            {
                Header: 'Roles',
                id: 'roles',
                sortable: false,
                Cell: ({ row }) =>
                    renderRoles(
                        row,
                        roleLocationMap.get(row.original.location_id),
                        location,
                        roles,
                        (option) => {
                            const key = row.original.location_id;
                            const value = { role_type: option.target.value };

                            const newMap = new Map(roleLocationMap);
                            setRoleLocationMap(newMap.set(key, value));
                        }
                    ),
                minWidth: 150,
            },
            {
                Header: 'Location',
                id: 'location_name',
                accessor: (row) => {
                    let name = getLocationDisplayName(row);
                    if (row.hierarchyChildren)
                        name += ` (${row.hierarchyChildren.length} locations under)`;
                    return name;
                },
                width: 180,
            },
            {
                Header: 'Level',
                id: 'location_type',
                accessor: (row) => displayStringForLocationType(row.location_type),
                width: 120,
            },
            {
                Header: 'State',
                id: 'state_code',
                accessor: computeLocationStateCode,
                width: 120,
            },
            {
                Header: 'Actions',
                id: 'actions',
                sortable: false,
                Cell: ({ row }) => renderActions(row, deleteLocation),
                width: 90,
            },
        ];
        return cols;
    }, [location, roleLocationMap, roles, setRoleLocationMap, deleteLocation]);

    return (
        <div className="simple-table-modal">
            <SimpleTable
                noDataText={'No Assigned Locations'}
                defaultSortId={'location_name'}
                data={[...selectedLocations]}
                columns={columns}
            />
        </div>
    );
}

RoleLocationTable.propTypes = {
    roleLocationMap: PropTypes.object,
    setRoleLocationMap: PropTypes.func,
    selectedLocations: PropTypes.object,
    location: PropTypes.object,
    roles: PropTypes.array,
    deleteLocation: PropTypes.func,
};

const propTypes = {
    loggedInUser: PropTypes.object,
    managerRole: PropTypes.object,
    modalAction: PropTypes.func,
    closeModal: PropTypes.func,
    generateQrCode: PropTypes.func,
    reloadTokens: PropTypes.func,
};
function CreateRangerTokenModal({
    loggedInUser,
    managerRole,
    modalAction,
    closeModal,
    generateQrCode,
    reloadTokens,
}) {
    const [selectedLocation, setSelectedLocation] = useState();
    const [locationList, setLocationList] = useState(new Set([]));
    const [showRoleLocationMapTable, setShowRoleLocationMapTable] = useState(true);
    const [showRoleLocationDisplay, setShowRoleLocationDisplay] = useState(false);
    const [isInvalidLocation, setIsInvalidLocation] = useState(false);
    const [roleLocationMap, setRoleLocationMap] = useState(new Map());

    useEffect(() => {
        if (locationList.size === 0) {
            setShowRoleLocationMapTable(true);
            setRoleLocationMap(new Map());
        }
    }, [locationList]);

    const changeLocation = (location) => {
        if (showRoleLocationMapTable || locationList.size === 0) {
            setSelectedLocation(location);
            setLocationList(locationList.add(location));
            setIsInvalidLocation(false);
        }
    };

    const addLocations = () => {
        if (selectedLocation === undefined) {
            setIsInvalidLocation(true);
            return;
        }
        setSelectedLocation();
        setIsInvalidLocation(false);
        setShowRoleLocationDisplay(true);
    };

    const deleteLocation = (location) => {
        locationList.delete(location);
        setLocationList(new Set(locationList));
        if (locationList.size === 0) {
            setShowRoleLocationDisplay(false);
            setSelectedLocation();
        }
    };

    const createRangerToken = async () => {
        try {
            const response = await createRangerAppToken(roleLocationMap)();
            setShowRoleLocationDisplay(false);
            modalAction('success', 'Issued new token successfully', response);
            await generateQrCode(response);
            reloadTokens();
        } catch (e) {
            modalAction('error', 'Internal error, please try again later');
        }
    };

    const filteredLocationList = new Set([]);
    const filteredDisplayList = new Set([]);

    locationList?.forEach((l) => {
        if (l.location_id === selectedLocation?.location_id) {
            filteredLocationList.add(l);
        } else {
            filteredDisplayList.add(l);
        }
    });

    return (
        <StyledModal
            size="lg"
            isOpen={true}
            heading="Create New Token"
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => closeModal()}
        >
            <p className="modal-text-padding">
                Please add a location and select a role in order to mint your token.
            </p>
            <Text fontWeight="bold" className="modal-text-padding">
                Select Location
            </Text>
            <FlexCol sm={12} md={12}>
                <Box padding="xs" className="location-select-box">
                    <Text color="white" size="lg">
                        Select a Facility
                    </Text>
                </Box>
                <LocationPicker
                    id="token-management-location"
                    selectedLocation={selectedLocation}
                    locationSelect={changeLocation}
                />
            </FlexCol>
            {showRoleLocationMapTable && filteredLocationList.size > 0 && (
                <RoleLocationTable
                    selectedLocations={filteredLocationList}
                    setRoleLocationMap={setRoleLocationMap}
                    roleLocationMap={roleLocationMap}
                    location={managerRole.location}
                    roles={loggedInUser.roles}
                    deleteLocation={deleteLocation}
                />
            )}
            <div className="add-locations-button">
                <Button
                    isDisabled={
                        roleLocationMap.size !== locationList.size ||
                        locationList.size === 0
                    }
                    appearance="secondary"
                    onClick={addLocations}
                >
                    Add Locations
                </Button>
                {isInvalidLocation && showRoleLocationMapTable && (
                    <Text size="sm">Select a facility before adding a location.</Text>
                )}
            </div>
            <Spacer size="lg" />
            {showRoleLocationDisplay && (
                <div>
                    <DisplayRoleLocation
                        assignedLocations={[...filteredDisplayList]}
                        deleteLocation={deleteLocation}
                    />
                    <Spacer size="lg" />
                </div>
            )}
            <ModalActions>
                <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                    <Button appearance="tertiary" onClick={() => closeModal()}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!showRoleLocationDisplay}
                        onClick={createRangerToken}
                    >
                        Mint
                    </Button>
                </ButtonGroup>
            </ModalActions>
        </StyledModal>
    );
}

CreateRangerTokenModal.propTypes = propTypes;

export default CreateRangerTokenModal;
