/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSanitizedRedirectUrl, IncompatibleBrowserNotice } from 'sarsaparilla';
import { HubLoginTermsOfService } from 'site-kit';
import { LoginForm } from 'ui-internal-account/src/components/login/loginForm';
import { MfaValidateForm } from 'ui-internal-account/src/components/login/MfaValidateForm';
import * as globals from 'ui-internal-account/src/constants/globals';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { checkLoggedIn, doMfaValidate, logIn, setEmail } from '../actions/login';
import MfaEnrollmentWizardModal from '../components/MfaEnrollmentWizardModal';
import ConfirmEmailAlert from '../components/ConfirmEmailAlert';
import Loading from './Loading';

const PANE_LOGIN = 'LOGIN';
const PANE_MFA_VALIDATE = 'MFA_VALIDATE';
const PANE_MFA_ENROLL = 'MFA_ENROLL';

const isLogin = (state) => state === PANE_LOGIN;
const isMfaValidate = (state) => state === PANE_MFA_VALIDATE;
const isMfaEnroll = (state) => state === PANE_MFA_ENROLL;

function MfaLoginPage(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const attempts = useSelector((state) => state.login.attempts);
    const {
        mfa_validate_required,
        mfa_enroll_required,
        mfa_opt_out_allowed,
        data,
        error,
    } = useSelector((state) => state.login) || {};

    const [mfaLoginState, setMfaLoginState] = useState(PANE_LOGIN);
    const [emailState, setEmailState] = useState('');
    const [redirectUrl, setRedirectUrl] = useState('');
    const [canSkipMfa, setCanSkipMfa] = useState(false);
    const [showConfirmEmailChange, setShowConfirmEmailChange] = useState(
        !isEmpty(props.query?.token) && isEmpty(error)
    );
    const existMFAToken = localStorage.getItem(globals.TOKEN_LOCAL_STORAGE_KEY) !== null;

    const searchParams = new URLSearchParams(window.location.search);
    const newConfirmFlow = props.query?.redirect?.includes('confirm-email-change');

    useEffect(() => {
        if (!existMFAToken && mfaLoginState === PANE_MFA_VALIDATE) {
            setMfaLoginState(PANE_LOGIN);
        }
    }, [existMFAToken, mfaLoginState]);

    if (mfa_opt_out_allowed && !canSkipMfa) {
        setCanSkipMfa(true);
    }

    if (!isMfaEnroll(mfaLoginState)) {
        dispatch(checkLoggedIn(null, history));
    }

    if (redirectUrl === '') {
        const redirect = getSanitizedRedirectUrl() ?? props.query?.redirect;
        if (props.query?.token) {
            setRedirectUrl(`${redirect}?token=${props.query?.token}`);
        } else {
            setRedirectUrl(redirect);
        }
    }

    if (mfa_validate_required && !isMfaValidate(mfaLoginState) && existMFAToken) {
        setMfaLoginState(PANE_MFA_VALIDATE);
    }

    if (mfa_enroll_required && !isMfaEnroll(mfaLoginState)) {
        setMfaLoginState(PANE_MFA_ENROLL);
    }

    if (searchParams.has('email')) {
        const email = searchParams.get('email');
        setEmailState(email);
        dispatch(setEmail(email));
        searchParams.delete('email');
    }

    if (data?.email) {
        setEmailState(data.email);
        dispatch(setEmail(data.email));
    }

    const setEmailHandler = (email) => {
        setEmailState(email);
        dispatch(setEmail(email));
    };

    const logInHandler = (email, password) => {
        const url = redirectUrl?.includes('/internal/account/confirm-email?')
            ? null
            : redirectUrl;
        dispatch(logIn(email, password, url, history));
    };

    const mfaValidateHandler = (code) => {
        dispatch(doMfaValidate(code, redirectUrl, history));
    };

    const toggleShowConfirmEmailChange = () => {
        setShowConfirmEmailChange(!showConfirmEmailChange);
    };

    return (
        <div>
            <ConfirmEmailAlert
                newConfirmFlow={newConfirmFlow}
                show={showConfirmEmailChange}
                setShow={toggleShowConfirmEmailChange}
            />

            <div className="logged-out-container">
                <Loading />

                {isLogin(mfaLoginState) && (
                    <LoginForm
                        loginFn={logInHandler}
                        setEmailFn={setEmailHandler}
                        initialEmail={emailState}
                        errorMessage={error}
                        attempts={attempts}
                    />
                )}

                {isMfaValidate(mfaLoginState) && (
                    <MfaValidateForm
                        submitFn={mfaValidateHandler}
                        error={error}
                        showStartOver
                    />
                )}

                {isMfaEnroll(mfaLoginState) && (
                    <MfaEnrollmentWizardModal canSkip={canSkipMfa} isEmbedded />
                )}
            </div>
            <HubLoginTermsOfService />
            <IncompatibleBrowserNotice />
        </div>
    );
}

MfaLoginPage.propTypes = {
    query: PropTypes.shape({
        redirect: PropTypes.string,
        token: PropTypes.string,
    }),
};

export default MfaLoginPage;
