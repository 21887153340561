/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sarsaparilla';

const propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    title: PropTypes.string,
    id: PropTypes.string,
};

export default function RowSelectCheckBox({ onChange, checked, title, id }) {
    return (
        <Checkbox
            id={id}
            onChange={onChange}
            isChecked={checked}
            label={title}
            isLabelVisible={false}
        />
    );
}
RowSelectCheckBox.propTypes = propTypes;
