/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import moment from 'moment-timezone';
import some from 'lodash/some';
import get from 'lodash/get';
import { filter, sortBy, flatten, map } from 'lodash';

import { AVAILABILITY_KEY_MAP_FORMAT, ISSUANCE_STATUS, SEASON_TYPES } from './constants';

// Requires that all keys and values be unique.
const transformIntoBidirectionalMap = (_map) => {
    const ret = new Map();
    _map.forEach((k, v) => {
        ret.set(k, v);
        ret.set(v, k);
    });
    return ret;
};

const issuanceStatuses = transformIntoBidirectionalMap(ISSUANCE_STATUS);

export function isSeasonOpen(seasons = []) {
    if (!seasons.length) {
        return false;
    }
    // if present day is in any or some seasons
    const today = moment().local();
    return some(
        seasons.map((season) => {
            const start = moment(
                season.display_start,
                AVAILABILITY_KEY_MAP_FORMAT
            ).local();
            //Pushes end date to end of the day (UTC) then changes to local time
            const end = moment(season.end_date, AVAILABILITY_KEY_MAP_FORMAT)
                .endOf('day')
                .local();
            return today.isBetween(start, end) && season.type !== SEASON_TYPES.Lottery;
        })
    );
}

export function isLottery(seasons) {
    if (!seasons.length) {
        return false;
    }
    // if present day is in any or some seasons
    return some(
        seasons.map((season) => {
            const today = moment().tz(season.timezone);
            const start = moment(
                `${season.display_start} ${season.display_time}`,
                AVAILABILITY_KEY_MAP_FORMAT
            ).tz(season.timezone);
            const end = moment(
                `${season.end_date} 15:00:00`,
                AVAILABILITY_KEY_MAP_FORMAT
            ).tz(season.timezone);
            return today.isBetween(start, end) && season.type === SEASON_TYPES.Lottery;
        })
    );
}

// Gets props.currentDate if it exists, otherwise gets the current time.
// Useful for faking what time it is for debugging purposes
export const getCurrentTime = (props) => {
    if (props.currentDate) {
        return moment.isMoment(props.currentDate)
            ? props.currentDate.utc()
            : moment(props.currentDate).utc();
    }

    let currentDate = moment().utc();
    // enable timemachine date query param only in dev
    if (process.env.NODE_ENV === 'development') {
        currentDate = moment(get(props, 'location.query.date', moment())).utc();
    }

    return currentDate;
};

export const isInvalidLotteryAward = (props) => {
    const { permit, lottery, issuance } = props;
    const incorrectStatus =
        issuance.status !== issuanceStatuses.get('Awarded') ||
        props.issuance.status === issuanceStatuses.get('Accepted');
    if (incorrectStatus) {
        return true;
    }
    const currentTime = getCurrentTime(props);
    const announcedTimestamp = get(lottery, 'lottery.announced_at', false);
    if (
        announcedTimestamp &&
        moment(announcedTimestamp).tz(permit.time_zone).isAfter(currentTime)
    ) {
        return true;
    }
    return false;
};

export const getEntrances = (permit, divisionId, divisionSpecificEntrances = false) => {
    const divLength = permit.divisions ? Object.keys(permit.divisions).length : 0;
    let entrances = [];
    if (divisionSpecificEntrances && divLength > 1) {
        const reservationDivision = permit?.divisions?.[divisionId];
        const reservationDivisionEntries = reservationDivision?.entry_ids ?? [];
        entrances = flatten(
            map(reservationDivisionEntries, (entrance_id) => {
                return filter(permit?.entrances ?? [], {
                    is_entry: true,
                    id: entrance_id,
                });
            })
        );
    } else {
        entrances = sortBy(filter(permit?.entrances ?? [], { is_entry: true }), 'id');
    }

    return entrances;
};

export const getExits = (permit, divisionId, divisionSpecificExits = false) => {
    const divLength = permit.divisions ? Object.keys(permit.divisions).length : 0;
    let exits = [];
    if (divisionSpecificExits && divLength > 1) {
        const reservationDivision = permit?.divisions?.[divisionId];
        const reservationDivisionExits = reservationDivision?.exit_ids ?? [];
        exits = flatten(
            map(reservationDivisionExits, (exit_id) => {
                return filter(permit?.entrances ?? [], { is_exit: true, id: exit_id });
            })
        );
    } else {
        exits = sortBy(filter(permit?.entrances ?? [], { is_exit: true }), 'id');
    }

    return exits;
};

export const matchPermitTypeFromMapping = (permitId, desiredPermitType, permitMapping) =>
    permitMapping?.hasOwnProperty(desiredPermitType) &&
    permitMapping[desiredPermitType]?.includes(permitId);

export const SAME_DAY_DEAD_LINE_MSG = (amPmTime) =>
    `You can only book a reservation on the same day up until ${amPmTime}. It is now past that time at this location.`;
