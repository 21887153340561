/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
    Heading,
    BlockTabs,
    Button,
    HelmetWrapperInternal,
    Icons,
    Panel,
} from 'sarsaparilla';

interface SubTab {
    title?: string;
    label?: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
}

interface Tab {
    title: string;
    ariaLabel: string;
    onClick: Function;
    selectedSubTabIndex: number;
    subTabs: SubTab[];
}

interface PageHeaderProps {
    children: React.ReactNode;
    title: string;
    selectedTabIndex: number;
    navBack?: string;
    breadcrumbs?: object[];
    tabs: Tab[];
}

export function PageHeader({
    children,
    title,
    selectedTabIndex,
    navBack,
    breadcrumbs,
    tabs,
}: PageHeaderProps) {
    const history = useHistory();

    const onNavBackClick = () => {
        const url = navBack;

        if (!url) {
            history.goBack();
        } else {
            history.push(url);
        }
    };

    const onTabClick = (index: number) => {
        const tab = tabs[index];
        if (tab.onClick) {
            tab.onClick(index);
        }
    };

    const onSubTabClick = (index: number) => {
        const tab = tabs[selectedTabIndex].subTabs[index];
        if (tab.onClick) {
            // @ts-ignore
            tab.onClick(index);
        }
    };

    const createTab = (tab: Tab, index: number) => {
        const key = `page-header-tab-${index}`;

        const isSelectedTab = index === selectedTabIndex;
        const classes = classNames({
            'rec-tab': true,
            'rec-tab-active': isSelectedTab,
        });

        return (
            <li key={key} className={classes}>
                <button
                    type="button"
                    className="rec-tab-link"
                    aria-label={tab.ariaLabel}
                    onClick={() => onTabClick(index)}
                >
                    {tab.title}
                </button>
            </li>
        );
    };

    const tabsUI = () => {
        const tabCheck = tabs && selectedTabIndex ? tabs[selectedTabIndex] : null;
        const classes = classNames({
            'rec-tab-list': true,
            'ia-rec-tab-list': true,
            'ia-rec-tab-list-with-subs': tabCheck && tabCheck.subTabs,
        });
        if (tabs && tabs.length > 0) {
            return (
                <div className={classes}>
                    <ul className="rec-tabs site-nav-tabs">{tabs.map(createTab)}</ul>
                </div>
            );
        }

        return null;
    };

    const createSubTab = (subTab: SubTab, index: number) => {
        const key = `page-header-sub-tab-${index}`;

        const selectedTab = tabs[selectedTabIndex];
        const selectedSubTabIndex = selectedTab.selectedSubTabIndex;

        const classes = classNames({
            active: index === selectedSubTabIndex,
            'page-header-sub-tab': true,
        });

        return (
            <Panel
                key={key}
                // @ts-ignore
                className={classes}
                label={subTab.title}
            />
        );
    };

    const subTabsUI = () => {
        if (tabs && tabs.length > selectedTabIndex) {
            const selectedTab = tabs[selectedTabIndex];

            if (selectedTab.subTabs) {
                return (
                    <div className="page-header-sub-tabs">
                        <BlockTabs
                            // @ts-ignore
                            className="rec-content-switcher"
                            activeIndex={selectedTab.selectedSubTabIndex}
                            selectedIndexChanged={(i) => onSubTabClick(i)}
                        >
                            {selectedTab.subTabs.map(createSubTab)}
                        </BlockTabs>
                    </div>
                );
            }
        }

        return null;
    };

    const renderBreadcrumbs = () => {
        if (!breadcrumbs) {
            return null;
        }

        const crumbs: JSX.Element[] = [];
        breadcrumbs.forEach((crumb: SubTab, i: number) => {
            if (crumb.onClick) {
                crumbs.push(
                    <Button
                        size="xs"
                        appearance="link"
                        className="button-as-anchor"
                        onClick={crumb.onClick}
                        key={`crumb_${i}`}
                    >
                        {crumb.label}
                    </Button>
                );
            } else {
                crumbs.push(<span key={`crumb_${i}`}>{crumb.label}</span>);
            }

            if (i + 1 !== breadcrumbs.length) {
                crumbs.push(<span className="breadcrumb-spacer" key={`spacer_${i}`} />);
            }
        });

        return <div className="page-breadcrumbs">{crumbs}</div>;
    };

    const renderHeaderTitle = () => {
        let headerToRender;
        if (navBack) {
            headerToRender = (
                <Button
                    appearance="link"
                    className="page-header-nav-back mr-2"
                    screenReaderTextBefore="Go Back to Previous Page"
                    onClick={onNavBackClick}
                    iconBeforeElement={<Icons.IconArrowBack />}
                />
            );
        }

        return (
            <div className="page-header-back-title-grid my-5">
                {headerToRender}
                <Heading headingLevel={1} appearance="h3">
                    {title}
                </Heading>
            </div>
        );
    };

    return (
        <div className="page-header-wrapper">
            <HelmetWrapperInternal title={title} />
            <div className="page-header">
                <div className="page-header-left">
                    {renderBreadcrumbs()}
                    {renderHeaderTitle()}
                    {tabsUI()}
                </div>
                {children}
            </div>
            {subTabsUI()}
        </div>
    );
}

export default PageHeader;
