/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import * as types from '../constants/types';

const emptyPOCUser = {
    user_id: '',
    first_name: '',
    last_name: '',
    email: '',
    roles: [],
    phone_numbers: [],
};

const emptyPOCObject = { name: '', role: '', email: '', phone: '' };

const emptyInfo = {
    result: [],
    success: null,
};

const initialState = {
    cache: {},
    location: null,
    info: {},
    fetchFacilityInfoSuccess: false,
    fetchFacilityInfoError: false,

    primaryPOCUser: emptyPOCUser,
    secondaryPOCUser: emptyPOCUser,
    additionalPOCObject: emptyPOCObject,
    submitPOCErrorType: null,

    notes: [],
    erroredNoteSubmissionIDs: [],
    shouldOpenModal: false,

    modalInfo: {
        mandatory: emptyInfo,
        external: emptyInfo,
        optional: emptyInfo,
        additional: emptyInfo,
        seasons: emptyInfo,
        rules: emptyInfo,
    },
};

const facilityInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_FACILITY_INFO_SUCCESS: {
            const value = {
                info: action.info,
                location:
                    action.info.location_path[
                        (action.info?.location_path?.length || 0) - 1
                    ],
                fetchFacilityInfoSuccess: true,
                fetchFacilityInfoError: false,

                primaryPOCUser: get(action.info, 'location_poc_primary.user_id', '')
                    .length
                    ? action.info.location_poc_primary
                    : emptyPOCUser,
                secondaryPOCUser: get(action.info, 'location_poc_secondary.user_id', '')
                    .length
                    ? action.info.location_poc_secondary
                    : emptyPOCUser,
                additionalPOCObject: get(
                    action.info,
                    'location_poc_additional',
                    emptyPOCObject
                ),
                submitPOCErrorType: null,

                notes: action.info.location_notes || [],
                noteErrorIdentifiers: [],
            };

            return {
                ...state,
                ...value,
                cache: {
                    ...state.cache,
                    [action.location_id]: action.info,
                },
            };
        }
        case types.FETCH_FACILITY_INFO_ERROR:
            return {
                ...state,
                location: action.location,
                info: {},
                fetchFacilityInfoSuccess: false,
                fetchFacilityInfoError: true,
            };
        case types.DELETE_FACILITY_INFO_CACHE: {
            const updatedCache = { ...state.cache };
            delete updatedCache[action.location_id];
            return {
                ...state,
                cache: updatedCache,
            };
        }
        case types.UPDATE_FACILITY_POC:
            return {
                ...state,
                primaryPOCUser: action.primaryUser || emptyPOCUser,
                secondaryPOCUser: action.secondaryUser || emptyPOCUser,
                additionalPOCObject: action.additionalPOC || emptyPOCObject,
                submitPOCErrorType: false,
            };
        case types.SET_FACILITY_POC_ERROR:
            return {
                ...state,
                submitPOCErrorType: true,
            };
        case types.CLEAR_FACILITY_POC_ERROR:
            return { ...state, submitPOCErrorType: null };
        case types.UPDATE_FACILITY_NOTE_SUCCESS: {
            const newNotes = [...state.notes];
            const newErrors = [...state.erroredNoteSubmissionIDs].filter(
                (error) => error.id === action.id && error.mandatory === action.mandatory
            );
            if (action.methodType === 'DELETE') {
                return {
                    ...state,
                    notes: newNotes.filter(
                        (note) => note.note_id !== action.note.note_id
                    ),
                    erroredNoteSubmissionIDs: newErrors,
                };
            }
            if (action.methodType === 'PUT') {
                return {
                    ...state,
                    notes: newNotes.map((note) => {
                        return note.note_id === action.note.note_id ? action.note : note;
                    }),
                    erroredNoteSubmissionIDs: newErrors,
                };
            }
            return {
                ...state,
                notes: newNotes.concat([action.note]),
                erroredNoteSubmissionIDs: newErrors,
            };
        }
        case types.ADD_FACILITY_NOTE_ERROR: {
            const newAddErrors = [...state.erroredNoteSubmissionIDs];
            if (
                newAddErrors.filter(
                    (error) =>
                        error.id === action.id && error.mandatory === action.mandatory
                ).length === 0
            ) {
                newAddErrors.push({ id: action.id, mandatory: action.mandatory });
            }
            return { ...state, erroredNoteSubmissionIDs: newAddErrors };
        }
        case types.SET_FACILITY_FLAG_SUCCESS:
            return {
                ...state,
                info: { ...state.info, collects_mobile_fees: action.payload },
            };
        case types.SET_SHOULD_OPEN_MODAL:
            return { ...state, shouldOpenModal: action.shouldOpen };
        case types.SET_FACILITY_FLAG_ERROR:
            return { ...state, locationFlagError: action.error };
        case types.CLEAR_FACILITY_NOTE_ERRORS:
            return { ...state, erroredNoteSubmissionIDs: [] };
        case types.SET_FACILITY_MODAL_RESULT:
            return {
                ...state,
                modalInfo: {
                    ...state.modalInfo,
                    [action.key]: {
                        result: action.result,
                        success: action.success,
                    },
                },
            };
        case types.LOGGED_OUT:
            return initialState;
        default:
            return state;
    }
};

export default facilityInfoReducer;
