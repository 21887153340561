/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import PageHeader from '../components/PageHeader';
import { creatingUser as creatingUserAction } from '../actions/updateUser';
import { setTabIndex as setTabIndexAction } from '../actions/userManagement';
import Attestation from './Attestation';

interface UserManagementHeaderState {
    userManagement: {
        selectedTabIndex: number;
    };
}

interface UserManagementHeaderProps {
    selectedTabIndex: number;
    setTabIndex: (index: number) => void;
    creatingUser: () => void;
    editing?: boolean;
}

export function UserManagementHeader({
    selectedTabIndex,
    setTabIndex,
    creatingUser,
    editing,
}: UserManagementHeaderProps) {
    const history = useHistory();

    const pageHeaderTabs = () => {
        const tabs = [
            {
                title: 'User List',
                onClick: () => {
                    setTabIndex(0);
                    history.push('/internal/account/manage-users/');
                },
                ariaLabel: 'Go to User List',
                selectedSubTabIndex: 0,
                subTabs: [],
            },
            {
                title: 'Create User',
                onClick: () => {
                    setTabIndex(1);
                    history.push('/internal/account/create-user');
                    creatingUser();
                },
                ariaLabel: 'Create New User',
                selectedSubTabIndex: 0,
                subTabs: [],
            },
        ];

        if (editing) {
            tabs.push({
                title: 'Edit User',
                onClick: () => {},
                ariaLabel: 'Edit Existing User',
                selectedSubTabIndex: 0,
                subTabs: [],
            });
        }

        return tabs;
    };

    return (
        <PageHeader
            title="Internal User Management"
            tabs={pageHeaderTabs()}
            selectedTabIndex={selectedTabIndex}
        >
            {selectedTabIndex === 0 && <Attestation />}
        </PageHeader>
    );
}

const mapStateToProps = (state: UserManagementHeaderState) => {
    return {
        selectedTabIndex: state.userManagement.selectedTabIndex,
    };
};

const mapDispatchToProps = (dispatch: Function) => {
    return {
        creatingUser: () => dispatch(creatingUserAction()),
        setTabIndex: (index: number) => dispatch(setTabIndexAction(index)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementHeader);
