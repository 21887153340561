/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { ErrorPage } from 'shared-ui';
import { SiteWrapper } from './SiteWrapper';

export default function InternalErrorPage() {
    return (
        <SiteWrapper>
            <div className="rec-has-error-wrap">
                <ErrorPage />
            </div>
        </SiteWrapper>
    );
}
