/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    unsavedCount: PropTypes.number,
    onAbort: PropTypes.func,
    formIsUnmodified: PropTypes.bool,
    onSubmit: PropTypes.func,
};

class FacilityInfoPageFooter extends PureComponent {
    static propTypes = propTypes;

    render() {
        return (
            <div className="rec-page-footer-wrapper" hidden={false}>
                <div className="rec-footer-content-wrapper">
                    <div className="rec-footer-status">
                        {this.props.unsavedCount} unsaved updates
                    </div>
                    <div className="buttons-wrap">
                        <button
                            type="button"
                            name="cancel"
                            className="rec-button-tertiary"
                            onClick={this.props.onAbort}
                            disabled={this.props.formIsUnmodified}
                        >
                            Undo All Changes
                        </button>
                    </div>
                    <div className="buttons-wrap">
                        <button
                            type="button"
                            name="submit"
                            className="rec-button-primary"
                            onClick={this.props.onSubmit}
                            disabled={this.props.formIsUnmodified}
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FacilityInfoPageFooter;
