/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import * as types from '../constants/types';
import { makePermitKey } from '../utilities/concessionaires';

export const initialState = {
    managedConcessionaires: [],
    assignedConcessionaire: null,
    loadAssignedConcessionaireError: false,
    loadPermitDetailsErrorKey: '',
    permitDetailsMap: {},
    loadPermitsError: false,
    permits: [],
};

const fetchManagedConcessionaires = (state = initialState, action) => {
    switch (action.type) {
        case types.CANCEL_UPDATE:
        case types.ACCOUNT_SAVED:
        case types.CREATING_USER:
        case types.EDITING_USER:
            return { ...state, managedConcessionaires: [] };
        case types.LOADING_MANAGED_CONCESSIONAIRES_SUCCESSFUL:
            return { ...state, managedConcessionaires: action.concessionaires };
        case types.DELETE_CONCESSIONAIRE:
            return {
                ...state,
                managedConcessionaires: [
                    action.concessionaire,
                    ...state.managedConcessionaires,
                ],
            };
        case types.ASSIGN_CONCESSIONAIRE: {
            const managedIndexToDelete = state.managedConcessionaires.indexOf(
                action.concessionaire
            );
            const managedConcessionaires =
                managedIndexToDelete >= 0
                    ? [
                          ...state.managedConcessionaires.slice(0, managedIndexToDelete),
                          ...state.managedConcessionaires.slice(managedIndexToDelete + 1),
                      ]
                    : state.managedConcessionaires;

            return { ...state, error: null, managedConcessionaires };
        }
        case types.LOADING_CONCESSIONAIRE_PERMITS_SUCCESSFUL:
            return {
                ...state,
                permits: action.concessionairePermits,
                loadPermitsError: false,
            };
        case types.LOADING_CONCESSIONAIRE_PERMITS_FAILED:
            return { ...state, permits: [], loadPermitsError: true };
        case types.GET_CONCESSIONAIRE_SUCCESS:
            return {
                ...state,
                assignedConcessionaire: action.concessionaire,
                loadAssignedConcessionaireError: false,
            };
        case types.GET_CONCESSIONAIRE_FAILED:
            return {
                ...state,
                assignedConcessionaire: null,
                loadAssignedConcessionaireError: true,
            };
        // OLD KILL //
        case types.LOADING_CONCESSIONAIRE_PERMIT_DETAILS_FAILED:
            return { ...state, loadPermitDetailsErrorKey: action.permitKeyForRequest };
        case types.LOADING_CONCESSIONAIRE_PERMIT_DETAILS_SUCCESSFUL: {
            const key = makePermitKey(action.details.permit);
            const mapUpdate = { ...state.permitDetailsMap, [key]: action.details };

            return {
                ...state,
                loadPermitDetailsErrorKey: '',
                permitDetailsMap: mapUpdate,
            };
        }
        // OLD //
        default:
            return state;
    }
};

export default fetchManagedConcessionaires;
