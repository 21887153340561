/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Heading, Text } from 'sarsaparilla';

const propTypes = {
    canSkip: PropTypes.bool,
    isLoading: PropTypes.bool,
    onOptOut: PropTypes.func,
    nextStep: PropTypes.func,
    startOver: PropTypes.func,
};

type MfaEnrollmentIntroductionProps = {
    canSkip: boolean;
    hideStartOverButton?: boolean;
    isLoading: boolean;
    onOptOut: () => void;
    nextStep: () => void;
    startOver: () => void;
};

function MfaEnrollmentIntroduction({
    canSkip,
    hideStartOverButton,
    isLoading,
    onOptOut,
    nextStep,
    startOver,
}: MfaEnrollmentIntroductionProps) {
    return (
        <>
            <p className="mt-3 ml-8 mb-4 mr-8">
                <Heading headingLevel={4} appearance="h4">
                    Set Up Multi-Factor Authentication
                </Heading>
            </p>
            <p className="ml-3 mr-3">
                <Text size="sm">
                    Multi-factor authentication will be available for use starting March
                    1st, 2025 to protect your {process.env.SITE_NAME} account with an
                    extra layer of security, based on federal mandate.
                </Text>
            </p>
            <p className="ml-2 mr-2 mb-3">
                <Text size="sm">
                    <b>Step 1.</b> To get started, download and install your
                    agency-mandated authentication application for your phone, tablet, or
                    desktop. When you have completed the download, click Next to proceed
                    to the remaining steps.
                </Text>
            </p>
            {canSkip && (
                <p>
                    <Button appearance="link" onClick={onOptOut} isDisabled={isLoading}>
                        Skip for now
                    </Button>
                </p>
            )}
            <p>
                <Button onClick={nextStep} isLoading={isLoading}>
                    Next
                </Button>
            </p>
            {!hideStartOverButton && (
                <p>
                    <Text size="sm">
                        <a
                            href="/internal/account/login"
                            className="rec-announcement-link-btn"
                            onClick={startOver}
                            rel="noreferrer"
                        >
                            Start over
                        </a>{' '}
                        and return to login page
                    </Text>
                </p>
            )}
        </>
    );
}

MfaEnrollmentIntroduction.propTypes = propTypes;

export default MfaEnrollmentIntroduction;
