/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    Button,
    ButtonGroup,
    Checkbox,
    ModalActions,
    Spacer,
    StyledModal,
    Alert,
} from 'sarsaparilla';
import { ReactTableWithLayout } from 'shared-ui';
import { fetchUsers, fetchUsersClear } from '../actions/fetchUsers';
import { filterChanged } from '../actions/userManagement';
import UserListThrottle from '../components/UserListThrottle';
import {
    errorForListingUsers,
    errorForGetMachineApiPermissions,
} from '../utilities/errorMessages';
import { displayStringForRoleType } from '../utilities/roles';
import SiteWrapper from './SiteWrapper';
import PageHeader from '../components/PageHeader';
import LocationDisplayByRoles from '../components/tableUtil/LocationDisplayByRoles';
import TextHighlight from '../components/tableUtil/TextHighlight';
import {
    fetchMachineApiPermissions,
    updateMachineApiPermissions,
} from '../actions/machineApi';

const propTypes = {
    userResults: PropTypes.any,
    dataLoaded: PropTypes.bool,
    fetchUsersError: PropTypes.any,
    location: PropTypes.object,
    machineApiPermissions: PropTypes.object,
    getMachineApiPermissionsError: PropTypes.bool,
    getUserList: PropTypes.func,
    clearUserList: PropTypes.func,
    changeFilter: PropTypes.func,
    fetchPermissions: PropTypes.func,
    updatePermissions: PropTypes.func,
};

function MachineApiPermissionsPage({
    userResults,
    dataLoaded,
    fetchUsersError,
    location,
    machineApiPermissions,
    getMachineApiPermissionsError,
    getUserList,
    clearUserList,
    changeFilter,
    fetchPermissions,
    updatePermissions,
}) {
    const [throttleString, setThrottleString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [facilityId, setFacilityId] = useState(null);
    const [facilityName, setFacilityName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [permissions, setPermissions] = useState({});
    const [permissionsLoaded, setPermissionsLoaded] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showNotification, setShowNotification] = useState(false);

    const classes = classNames({
        'user-list-box': true,
        throttle: true,
        'has-data': dataLoaded,
    });

    const editPermissions = async (row) => {
        setPermissionsLoaded(false);
        if (facilityId) {
            await fetchPermissions(facilityId, row.user_id);
            setUserId(row.user_id);
            setPermissionsLoaded(true);
        }
        setShowModal(true);
    };

    const renderText = (txt) => {
        return <TextHighlight body={txt} />;
    };

    const renderLocation = (row) => {
        return (
            <LocationDisplayByRoles
                roles={row.original.roles}
                onEdit={() => editPermissions(row.original)}
            />
        );
    };

    const renderActions = (row) => {
        return (
            <Button
                className="edit-user-button"
                appearance="link"
                onClick={() => editPermissions(row.original)}
            >
                Edit Permissions
            </Button>
        );
    };

    const getTableColumns = [
        {
            Header: 'First Name',
            accessor: 'first_name',
            sortable: true,
            Cell: ({ row }) => renderText(row.original.first_name),
            minWidth: 70,
        },
        {
            Header: 'Last Name',
            accessor: 'last_name',
            sortable: true,
            Cell: ({ row }) => renderText(row.original.last_name),
            minWidth: 70,
        },
        {
            Header: 'Email',
            accessor: 'email',
            sortable: false,
            Cell: ({ row }) => renderText(row.original.email),
            minWidth: 110,
        },
        {
            Header: 'Locations',
            id: 'location',
            sortable: true,
            accessor: (row) => {
                return row.roles.map((role) => role.location.location_name).join(', ');
            },
            Cell: ({ row }) => renderLocation(row),
            minWidth: 100,
        },
        {
            Header: 'Role',
            id: 'role',
            sortable: true,
            accessor: (row) => {
                return row.roles && row.roles.length ? row.roles[0].role_type : '';
            },
            Cell: ({ row }) => displayStringForRoleType(row.original.roles[0].role_type),
            minWidth: 70,
        },
        {
            Header: 'Status',
            accessor: 'locked',
            minWidth: 40,
            maxWidth: 100,
            sortable: true,
            Cell: ({ row }) => (row.original.locked ? 'Locked' : 'Unlocked'),
        },
        {
            Header: 'Actions',
            minWidth: 60,
            maxWidth: 160,
            sortable: false,
            Cell: ({ row }) => renderActions(row),
        },
    ];

    const clearState = () => {
        setShowModal(false);
        setShowError(false);
        setPermissionsLoaded(false);
    };

    const clearFilters = () => {
        changeFilter({ textFilter: '', roleFilter: '', statusFilter: '' });
    };

    const clearThrottleSearch = () => {
        clearUserList();
        clearState();
        setThrottleString('');
    };

    const runThrottleSearch = (params, newThrottleString) => {
        if (params.location_id && params.location_type) {
            const locationSearchParams = {
                location_id: params.location_id,
                location_type: params.location_type,
            };
            getUserList([locationSearchParams]);
        } else {
            getUserList([params]);
        }
        if (newThrottleString !== throttleString) {
            clearFilters();
        }
        setThrottleString(newThrottleString);
    };

    const handleCheckboxChange = (evt) => {
        const field = evt.target.id;
        const value = evt.target.checked;
        setPermissions({
            ...permissions,
            [field]: value,
        });
    };

    const displayNotification = () => {
        setShowNotification(true);
        setTimeout(() => {
            setShowNotification(false);
        }, 5000);
    };

    const grantPermissions = async () => {
        if (facilityId && userId) {
            await updatePermissions(facilityId, userId, permissions);
            displayNotification();
        }
        setShowModal(false);
    };

    useEffect(() => {
        const locationId =
            location?.location_id && location.location_id !== '1'
                ? location.location_id
                : null;
        if (locationId) {
            setFacilityId(locationId);
            setFacilityName(location.location_description);
        }
        clearState();
    }, [location]);

    useEffect(() => {
        setShowError(getMachineApiPermissionsError);
    }, [getMachineApiPermissionsError]);

    useEffect(() => {
        setPermissions(machineApiPermissions);
    }, [machineApiPermissions]);

    let errorMessage = '';
    if (fetchUsersError) {
        errorMessage = errorForListingUsers(fetchUsersError);
    }

    return (
        <SiteWrapper>
            <div className="page-title">
                <PageHeader title="Machine API Permissions Management" />
            </div>
            <div id="UserManagement" className="page-content wrapper">
                <div>
                    <div className={classes}>
                        <UserListThrottle
                            id="UserThrottleSearch"
                            hasRan={dataLoaded}
                            run={runThrottleSearch}
                            clear={clearThrottleSearch}
                        />
                        {showNotification && (
                            <Alert shouldFocusOnMount type="success">
                                User permissions updated successfully.
                            </Alert>
                        )}
                        {showError && throttleString.length > 0 && permissionsLoaded && (
                            <Alert shouldFocusOnMount type="error">
                                {errorForGetMachineApiPermissions()}
                            </Alert>
                        )}
                        {errorMessage.length > 0 && (
                            <Alert shouldFocusOnMount type="error">
                                {errorMessage}
                            </Alert>
                        )}
                        {errorMessage.length === 0 &&
                            (throttleString.length > 0 || !!userResults.length) && (
                                <div className="user-list-table-wrap">
                                    <ReactTableWithLayout
                                        noDataText={`No users found${throttleString.length ? ` for [ ${throttleString} ]` : ''}`}
                                        columns={getTableColumns}
                                        defaultSorted={[{ id: 'last_name', desc: false }]}
                                        data={userResults}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <StyledModal
                isOpen={showModal && !showError}
                heading={
                    facilityId
                        ? `Machine API Permissions for ${facilityName}`
                        : 'Attention'
                }
                size="lg"
                onRequestClose={() => setShowModal(false)}
            >
                <div className="ia-facmodal">
                    <div className="ia-facmodal-section">
                        {facilityId ? (
                            <>
                                <div className="ia-facmodal-title">
                                    <span>Permissions:</span>
                                </div>
                                <div className="ia-facmodal-body">
                                    <fieldset>
                                        <div>
                                            <Checkbox
                                                isChecked={permissions?.test_permissions}
                                                onChange={handleCheckboxChange}
                                                label="Test Permissions"
                                                id="test_permissions"
                                            />
                                            <Spacer size="sm" />
                                            <Checkbox
                                                isChecked={permissions?.sync_read_only}
                                                onChange={handleCheckboxChange}
                                                label="Sync Read Only"
                                                id="sync_read_only"
                                            />
                                            <Spacer size="sm" />
                                            <Checkbox
                                                isChecked={permissions?.sync_write_only}
                                                onChange={handleCheckboxChange}
                                                label="Sync Write Only"
                                                id="sync_write_only"
                                            />
                                            <Spacer size="sm" />
                                            <Checkbox
                                                isChecked={permissions?.mock_read_only}
                                                onChange={handleCheckboxChange}
                                                label="Mock Read Only"
                                                id="mock_read_only"
                                            />
                                            <Spacer size="sm" />
                                            <Checkbox
                                                isChecked={permissions?.mock_write_only}
                                                onChange={handleCheckboxChange}
                                                label="Mock Write Only"
                                                id="mock_write_only"
                                            />
                                        </div>
                                    </fieldset>
                                </div>
                            </>
                        ) : (
                            <div className="ia-facmodal-title">
                                <span>Please select a facility first and try again.</span>
                            </div>
                        )}
                    </div>
                </div>
                <ModalActions>
                    <ButtonGroup isFullWidthOnMobile={false} isStretchedToFit>
                        <Button appearance="tertiary" onClick={() => setShowModal(false)}>
                            {facilityId ? 'Cancel' : 'Close'}
                        </Button>
                        {facilityId && <Button onClick={grantPermissions}>Grant</Button>}
                    </ButtonGroup>
                </ModalActions>
            </StyledModal>
        </SiteWrapper>
    );
}

MachineApiPermissionsPage.propTypes = propTypes;

const mapStateToProps = (state) => {
    return {
        dataLoaded: state.fetchUsers.userResultsLoaded,
        userResults: state.fetchUsers.userResults,
        fetchUsersError: state.fetchUsers.error,
        location: state.machineApi.location,
        machineApiPermissions: state.machineApi.machineApiPermissions,
        getMachineApiPermissionsError: state.machineApi.getMachineApiPermissionsError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearUserList: () => dispatch(fetchUsersClear()),
        getUserList: (paramArray) => dispatch(fetchUsers(paramArray)),
        changeFilter: (payload) => dispatch(filterChanged(payload)),
        fetchPermissions: (facilityId, userId) =>
            dispatch(fetchMachineApiPermissions(facilityId, userId)),
        updatePermissions: (facilityId, userId, payload) =>
            dispatch(updateMachineApiPermissions(facilityId, userId, payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineApiPermissionsPage);
