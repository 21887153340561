/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

// re-export all shared constants so that shared constants don't need to be explicitly referenced in our codebase
// import * as sharedConstants from './shared-constants';
import { matchPermitTypeFromMapping } from '../../shared-components/utils/permit-utils';

export * from './shared-constants';

/**
 * These constants are used elsewhere, but never defined. Adding them here
 * as undefined to stop console warnings. - Michael Stanaland, 10/9/2019
 */
export const REQUIRED_LIST_ENTRY_FIELD = undefined;
export const REQUIRED_GROUP_MEMBER_LIST = undefined;
export const CLEAR_NEXT_AVAILABLE = undefined;
export const GET_ISSUANCE_ERROR_BY_STATUS = undefined;

// launch darkly flags
export const MAP_PROVIDER_FEATURE = 'map-provider';

// permit
export const GET_PERMIT = 'GET_PERMIT';
export const GET_PERMIT_ERROR = 'GET_PERMIT_ERROR';
export const GET_ALL_PERMITS = 'GET_ALL_PERMITS';
export const GET_ALL_PERMITS_ERROR = 'GET_ALL_PERMITS_ERROR';
export const GET_PERMIT_RULES = 'GET_PERMIT_RULES';
export const GET_PERMIT_SEASONS = 'GET_PERMIT_SEASONS';
export const GET_PERMIT_RESERVATION_ALERT = 'GET_PERMIT_RESERVATION_ALERT';
export const GET_PERMIT_RESERVATION_ALERT_ERROR = 'GET_PERMIT_RESERVATION_ALERT_ERROR';
export const GET_AGGREGATE_SEASON_RULES = 'GET_AGGREGATE_SEASON_RULES';
export const GET_AGGREGATE_SEASON_ERROR = 'GET_AGGREGATE_SEASON_ERROR';
export const RIVER_FILLER_TEXT =
    'The Selway River flows through portions of north-central Idaho, within the Selway-Bitterroot Wilderness. The Selway is unique because it is the only river in the United States that received instant inclusion as a wild river, in both the National Wilderness Preservation System and the Wild and Scenic Rivers System. The natural beauty of the canyon, combined with the challenge of the rapids and the outstanding opportunities for solitude, make the Selway one of //the highest quality whitewater rivers in the nation.';

export const GET_STAYLIMIT_RULE = 'GET_STAYLIMIT_RULE';
export const GET_STAYLIMIT_RULE_ERROR = 'GET_STAYLIMIT_RULE_ERROR';

export const GET_PERMIT_GALLERY = 'GET_PERMIT_GALLERY';

// recarea
export const GET_RECAREA = 'GET_RECAREA';
export const GET_RECAREA_ERROR = 'GET_RECAREA_ERROR';
export const SET_DISTRICTS = 'SET_DISTRICTS';

// availability
export const GET_AVAILABILITY = 'GET_AVAILABILITY';
export const GET_AVAILABILITY_ERROR = 'GET_AVAILABILITY_ERROR';
export const GET_ALL_AVAILABILITIES = 'GET_ALL_AVAILABILITIES';
export const GET_ALL_AVAILABILITIES_ERROR = 'GET_ALL_AVAILABILITIES_ERROR';
export const CLEAR_AVAILABILITIES = 'CLEAR_AVAILABILITIES';
export const CLEAR_RECAREA_ERROR = 'CLEAR_RECAREA_ERROR';

// issuance
export const POST_ISSUANCE = 'POST_ISSUANCE';
export const POST_ISSUANCE_ERROR = 'POST_ISSUANCE_ERROR';
export const POST_ISSUANCE_CALCULATE_FEES = 'POST_ISSUANCE_CALCULATE_FEES';
export const POST_ISSUANCE_CALCULATE_FEES_ERROR = 'POST_ISSUANCE_CALCULATE_FEES_ERROR';
export const POST_ISSUANCE_CARTLESS = 'POST_ISSUANCE_CARTLESS';
export const POST_ISSUANCE_CARTLESS_ERROR = 'POST_ISSUANCE_CARTLESS_ERROR';
export const SET_ISSUANCE_AFTER_CHANGE_GROUP_SIZE =
    'SET_ISSUANCE_AFTER_CHANGE_GROUP_SIZE';
export const UPDATE_ISSUANCE = 'UPDATE_ISSUANCE';
export const UPDATE_ISSUANCE_ERROR = 'UPDATE_ISSUANCE_ERROR';
export const GET_ISSUANCE = 'GET_ISSUANCE';
export const GET_ISSUANCE_ERROR = 'GET_ISSUANCE_ERROR';
export const GET_ALL_ISSUANCES = 'GET_ALL_ISSUANCES';
export const GET_ALL_ISSUANCES_ERROR = 'GET_ALL_ISSUANCES_ERROR';
export const GET_ISSUANCE_BY_STATUS = 'GET_ISSUANCE_BY_STATUS';
export const GET_ISSUANCE_BY_STATUS_ERROR = 'GET_ISSUANCE_BY_STATUS_ERROR';
export const GET_ISSUANCE_PDF = 'GET_ISSUANCE_PDF';
export const GET_ISSUANCE_PDF_ERROR = 'GET_ISSUANCE_PDF_ERROR';
export const CANCEL_ISSUANCE = 'CANCEL_ISSUANCE';
export const CANCEL_ISSUANCE_ERROR = 'CANCEL_ISSUANCE_ERROR';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const GET_USER_ISSUANCES = 'GET_USER_ISSUANCES';
export const GET_USER_ISSUANCES_ERROR = 'GET_USER_ISSUANCES_ERROR';
export const GET_ISSUANCE_ACCESS_INFO = 'GET_ISSUANCE_ACCESS_INFO';
export const GET_ISSUANCE_ACCESS_INFO_ERROR = 'GET_ISSUANCE_ACCESS_INFO_ERROR';

export const GET_NEXT_AVAILABLE = 'GET_NEXT_AVAILABLE';
export const GET_NEXT_AVAILABLE_ERROR = 'GET_NEXT_AVAILABLE_ERROR';

export const ISSUANCE_UPDATE_REQUIRES_PAYMENT = 'UpdateRequiresPayment';
export const ISSUANCE_UPDATE_SUCCESSFUL = 'UpdateSuccessful';
export const ISSUANCE_UPDATE_REQUIRES_REFUND = 'UpdateRequiresRefund'; // go to confirmation page / populate redux
export const ISSUANCE_UPDATE_VALIDATION_ERROR = 'ValidationError';

// Rules
export const STAY_LIMIT_RULE_NAME = 'StayLimit';
export const IS_SAME_DAY_TRIP_ITINERARY_ALLOWED_RULE_NAME =
    'IsSameDayTripItineraryAllowed';
export const FLEXIBLE_ITINERARY_ALLOWED = 'FlexibleItineraryAllowed';
export const ITINERARY_START_NEXT_DAY = 'ItineraryStartNextDay';
export const GUIDES_COUNT_TOWARDS_GROUP_SIZE = 'GuidesCountTowardsGroupSize';
export const QUOTA_VISIBILITY = 'QuotaVisibility';
export const LOTTERY_REGISTRATION_ONLY = 'LotteryRegistrationOnly';
export const SPECIAL_PRINTING_TEXT = 'SpecialPrintingText';
export const SPECIAL_ENTRY_DATE_TEXT = 'SpecialEntryDateText';
export const DISABLE_MODIFY_GROUP_LIST = 'DisableModifyGroupList';
export const ITINERARY_STAY_LIMIT_PER_DIVISION = 'ItineraryStayLimitPerDivision';

// lottery
export const GET_LOTTERY = 'GET_LOTTERY';
export const GET_LOTTERY_ERROR = 'GET_LOTTERY_ERROR';
export const GET_LOTTERIES = 'GET_LOTTERIES';
export const GET_LOTTERIES_ERROR = 'GET_LOTTERIES_ERROR';
export const GET_LOTTERIES_DAILY = 'GET_LOTTERIES_DAILY';
export const GET_LOTTERIES_DAILY_ERROR = 'GET_LOTTERIES_DAILY_ERROR';
export const POST_LOTTERY = 'POST_LOTTERY';
export const POST_LOTTERY_ERROR = 'POST_LOTTERY_ERROR';
export const NOTIFY_LOTTERY_RESULTS = 'NOTIFY_LOTTERY_RESULTS';
export const NOTIFY_LOTTERY_RESULTS_ERROR = 'NOTIFY_LOTTERY_RESULTS_ERROR';
export const VERIFY_LOTTERY = 'VERIFY_LOTTERY';
export const VERIFY_LOTTERY_ERROR = 'VERIFY_LOTTERY_ERROR';

//express memberships (print at home)
export const GET_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID =
    'GET_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID';
export const GET_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID_ERROR =
    'GET_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID_ERROR';
export const GET_ALL_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID =
    'GET_ALL_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID';
export const GET_ALL_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID_ERROR =
    'GET_ALL_EXPRESS_MEMBERSHIPS_FOR_PERMIT_BY_ID_ERROR';
export const GET_EXPRESS_MEMBERSHIP_PRINT_DATE_FOR_PERMIT_BY_ISSUANCE_ID =
    'GET_EXPRESS_MEMBERSHIP_PRINT_DATE_FOR_PERMIT_BY_ISSUANCE_ID';
export const GET_EXPRESS_MEMBERSHIP_PRINT_DATE_FOR_PERMIT_BY_ISSUANCE_ID_ERROR =
    'GET_EXPRESS_MEMBERSHIP_PRINT_DATE_FOR_PERMIT_BY_ISSUANCE_ID_ERROR';

// verify lottery statuses
export const VERIFY_LOTTERY_STATUSES = {
    LOTTERY_AVAILABLE: 0,
    USER_RESTRICTED: 1,
    LOTTERY_NOT_ACTIVE: 2,
    LOTTERY_NOT_OPEN: 3,
    EXISTING_LOTTERY: 4,
    EXISTING_HIGH_RESERVATION: 5,
    COMMERCIAL_NOT_ALLOWED: 6,
};

// campsite
export const GET_CAMPSITES = 'GET_CAMPSITES';
export const GET_CAMPSITES_ERROR = 'GET_CAMPSITES_ERROR';

// account
export const GET_ACCOUNT_NAME = 'GET_ACCOUNT_NAME';
export const GET_ACCOUNT_NAME_ERROR = 'GET_ACCOUNT_NAME_ERROR';

// account (commercial)
export const GET_ACCOUNT_EMPLOYEES = 'GET_ACCOUNT_EMPLOYEES';
export const GET_ACCOUNT_EMPLOYEES_ERROR = 'GET_ACCOUNT_EMPLOYEES_ERROR';

// reservation detail
export const PERMIT = 'PERMIT';
export const LOTTERY = 'LOTTERY';

export const SEND_CURRENT_DATE = 'SEND_CURRENT_DATE';

export const EPOCH_MULTIPLIER = 1000;

export const DATE_TIME_RULES = [
    'HighUseRegistrationStart',
    'HighUseRegistrationEnd',
    'LotteryResultsAvailable',
    'HighUseSeasonStart',
    'HighUseSeasonEnd',
    'SeasonStart',
    'SeasonEnd',
    'LotteryDeadlineToConfirm',
    'DisplayDate',
    'LotteryDisplayDate',
    'LotteryReleaseDate',
    'ReleaseLockStart',
    'ReleaseLockEnd',
    'RegistrationStart',
    'RegistrationEnd',
    'SharedQuotaStartDate',
    'SharedQuotaEndDate',
];

// alternate permit holder
export const VERIFY_ALTERNATE = 'VERIFY_ALTERNATE';
export const VERIFY_ALTERNATE_ERROR = 'VERIFY_ALTERNATE_ERROR';

export const CONFIRM_ALTERNATE = 'CONFIRM_ALTERNATE';
export const CONFIRM_ALTERNATE_ERROR = 'CONFIRM_ALTERNATE_ERROR';

export const DECLINE_ALTERNATE = 'DECLINE_ALTERNATE';
export const DECLINE_ALTERNATE_ERROR = 'DECLINE_ALTERNATE_ERROR';

// clear errors
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const CLEAR_UPDATE_ISSUANCE_ERROR = 'CLEAR_UPDATE_ISSUANCE_ERROR';
export const CLEAR_FETCH_ERROR = 'CLEAR_FETCH_ERROR';
export const CLEAR_CART_ERROR = 'CLEAR_CART_ERROR';
export const CLEAR_FETCH_ISSUANCE_ERROR = 'CLEAR_FETCH_ISSUANCE_ERROR';
export const CLEAR_AVAILABILITY_ERROR = 'CLEAR_AVAILABILITY_ERROR';
export const CLEAR_POST_ISSUANCE_ERROR = 'CLEAR_POST_ISSUANCE_ERROR';
export const CLEAR_FETCH_ISSUANCE_STATUS = 'CLEAR_FETCH_ISSUANCE_STATUS';
export const CLEAR_FETCH_LOTTERY_ERROR = 'CLEAR_FETCH_LOTTERY_ERROR';

// fee display models
export const NONE_FEE = 'None';
export const ONE_FEE = 'OneFee';
export const ADD_ON_FEE = 'AddOnFee';
export const BY_DAY_FEE = 'ByDayFee';

// fee calculation models
export const NO_USE_FEE = 'NoUseFee';
export const FLAT_USE_FEE = 'FlatUseFee';
export const PER_GROUP_PER_NIGHT_FEE = 'PerGroupPerNight';
export const PER_GROUP_PER_DAY_FEE = 'PerGroupPerDay';
export const PER_MEMBER_FEE = 'PerMember';
export const PER_MEMBER_PER_NIGHT_FEE = 'PerMemberPerNight';
export const PER_MEMBER_PER_DAY_FEE = 'PerMemberPerDay';
export const PER_GROUP_SIZE_PER_DAY_FEE = 'PerGroupSizePerDay';
export const PER_WATERCRAFT_FEE = 'PerWatercraft';
export const PER_GROUP_PER_NIGHT_CUSTOM_RANGE_HIGH_USE_ONLY_FEE =
    'PerGroupPerNightCustomRangeHighUseOnly';
export const PER_GROUP_PER_NIGHT_EXCLUDE_FIRST_NIGHT_FEE =
    'PerGroupPerNightExcludeFirstNight';
export const PER_WATERCRAFT_PER_DAY = 'PerWatercraftPerDay';
export const PER_MEMBER_INYO = 'PerMemberInyo'; // not used in core permits
export const PER_GROUP_SIZE_PER_NIGHT_PARTIAL_FIRST_NIGHT_FEE =
    'PerGroupSizePerNightPartialFirstNight';
export const PER_MEMBER_HIGH_USE_ONLY = 'PerMemberHighUseOnly';
export const PER_MEMBER_PER_DAY_BY_SEASON = 'PerMemberPerDayBySeason';

// fee types
export const RECREATION_FEE = 'RecreationFee';
export const RESERVATION_FEE = 'ReservationFee';
export const PERCENT_DISCOUNT_FEE = 'PercentDiscountFee';
export const LOTTERY_APPLICATION_FEE = 'LotteryFee';
export const CLIN_FEE = 'CLIN';
export const TRANSACTION_FEE = 'TransactionFee';
export const USE_FEE = 'UseFee';
export const TICKET_FEE = 'TicketFee';
export const ATTRIBUTE_FEE = 'AttributeFee';
export const POS_FEE = 'POSFee';
export const TAX_FEE = 'TaxFee';
export const ATTRIBUTE_FEE_DESCRIPTION = 'FeeDescription';
export const NONREFUNDABLE_FEE = 'NonRefundable';
export const ADJUSTMENT_FEE = 'AdjustmentFee';

export const NONREFUNDABLE_LABEL = 'Non-refundable Use Fee';
export const RES_ID_DELIMITER = '__';

// cart
export const GET_CART = 'GET_CART';
export const GET_CART_ERROR = 'GET_CART_ERROR';
export const CANCEL_RESERVATION = 'CANCEL_RESERVATION';
export const CANCEL_RESERVATION_ERROR = 'CANCEL_RESERVATION_ERROR';

// issuance readable statuses
export const PERMIT_PRELIMINARY_READABLE = 'Preliminary';
export const PERMIT_IN_PROGRESS_READABLE = 'In Progress';
export const PERMIT_COMPLETE_READABLE = 'Reserved';
export const PERMIT_ISSUED_READABLE = 'Issued';
export const PERMIT_NO_SHOW_READABLE = 'No Show';
export const PERMIT_CANCELLED_READABLE = 'Cancelled';
export const PERMIT_REFUNDED_READABLE = 'Refunded';
export const LOTTERY_IN_PROGRESS_READABLE = 'In Progress';
export const LOTTERY_APPLIED_READABLE = 'Applied';
export const LOTTERY_AWARDED_READABLE = 'Awarded';
export const LOTTERY_AWARDED_IN_PROGRESS_READABLE = 'Awarded And Being Booked';
export const LOTTERY_LOST_READABLE = 'Unsuccessful';
export const LOTTERY_DECLINED_READABLE = 'Declined';
export const LOTTERY_ACCEPTED_READABLE = 'Accepted';
export const LOTTERY_NO_RESPONSE_READABLE = 'No Response';
export const LOTTERY_CANCELLED_READABLE = 'Cancelled';
export const LOTTERY_VOIDED_READABLE = 'Voided';
export const LOTTERY_DISQUALIFIED_READABLE = 'Disqualified';
export const LOTTERY_PENDING_READABLE = 'Pending';
export const PERMIT_IN_REVIEW_READABLE = 'Reserved - In Review';
export const PERMIT_REVIEWED_READABLE = 'Reserved - Reviewed';

// issuance statuses tooltips
export const LOTTERY_ACCEPTED_TOOLTIP = '';
export const LOTTERY_APPLIED_TOOLTIP = '';
export const LOTTERY_AWARDED_IN_PROGRESS_TOOLTIP = '';
export const LOTTERY_AWARDED_TOOLTIP = '';
export const LOTTERY_CANCELLED_TOOLTIP = '';
export const LOTTERY_DECLINED_TOOLTIP = '';
export const LOTTERY_DISQUALIFIED_TOOLTIP = '';
export const LOTTERY_IN_PROGRESS_TOOLTIP = '';
export const LOTTERY_LOST_TOOLTIP = '';
export const LOTTERY_NO_RESPONSE_TOOLTIP = '';
export const LOTTERY_PENDING_TOOLTIP = '';
export const LOTTERY_VOIDED_TOOLTIP = '';
export const PERMIT_CANCELLED_TOOLTIP = 'Reservation has been cancelled.';
export const PERMIT_COMPLETE_TOOLTIP = 'Reservation complete; payment processed.';
export const PERMIT_IN_PROGRESS_TOOLTIP = '';
export const PERMIT_ISSUED_TOOLTIP = 'Permit has been printed/issued.';
export const PERMIT_NO_SHOW_TOOLTIP =
    'Internal user has marked reservation as "no show".';
export const PERMIT_PRELIMINARY_TOOLTIP =
    "Reservation is in customer's cart, not yet purchased.";
export const PERMIT_REFUNDED_TOOLTIP =
    'Internal user has refunded the reservation via the "refund" button, where available';
export const PERMIT_IN_REVIEW_TOOLTIP = 'Permit is in review by the facility';
export const PERMIT_REVIEWED_TOOLTIP = 'Permit has been reviewed by the facility';

// issuance types
export const PRIVATE = 'Non-Commercial';
export const COMMERCIAL = 'Commercial';

// issuance quota types
export const ADVANCED = 'Advanced';
export const WALKUP = 'Walkup';

// internal validations
export const IS_LOTTERY_NAME_EMPTY = 'IsLotteryNameEmpty';
export const IS_LOTTERY_START_DATE_EMPTY = 'IsLotteryStartDateEmpty';
export const IS_LOTTERY_CLOSE_DATE_EMPTY = 'IsLotteryCloseDateEmpty';
export const IS_LOTTERY_RUN_DATE_EMPTY = 'IsLotteryRunDateEmpty';
export const IS_LOTTERY_DEADLINE_DATE_EMPTY = 'IsLotteryDeadlineDateEmpty';
export const IS_LOTTERY_INFO_DISPLAY_DATE_EMPTY = 'IsLotteryInfoDisplayDateEmpty';
export const IS_LOTTERY_NOTIFY_DATE_EMPTY = 'IsLotteryNotifyDateEmpty';
export const IS_SEASON_START_DATE_EMPTY = 'IsSeasonStartDateEmpty';
export const IS_SEASON_END_DATE_EMPTY = 'IsSeasonEndDateEmpty';

// external validations
export const IS_LAUNCH_LOCATION_EMPTY = 'IsLaunchLocationEmpty';
export const IS_TAKEOUT_LOCATION_EMPTY = 'IsTakeoutLocationEmpty';
export const IS_ISSUE_STATION_EMPTY = 'IsIssueStationEmpty';
export const IS_ENTRY_DATE_EMPTY = 'IsEntryDateEmpty';
export const IS_EXIT_DATE_EMPTY = 'IsExitDateEmpty';
export const IS_GROUP_MEMBER_EMPTY = 'IsGroupMemberEmpty';
export const IS_EQUIPMENT_EMPTY = 'IsEquipmentEmpty';
export const IS_NEED_TO_KNOW_CHECKED = 'IsNeedToKnowChecked';
export const IS_TRAVEL_METHOD_EMPTY = 'IsTravelMethodEmpty';
export const IS_BRINGING_ANIMALS_EMPTY = 'IsBringingAnimalsEmpty';
export const IS_VISITING_MT_WHITNEY_EMPTY = 'IsVisitingMtWhitneyEmpty';
export const IS_CAMPSITE_PREFERENCES_EMPTY = 'IsCampsitePreferencesEmpty';
export const IS_FIRST_CAMPSITE_INPUT_EMPTY = 'IsFirstCampsiteEmpty';
export const ARE_CHOICES_SELECTED = 'AreChoicesSelected';
export const IS_CHOICE_DATE_EMPTY = 'IsChoiceDateEmpty';
export const IS_CHOICE_END_DATE_EMPTY = 'IsChoiceEndDateEmpty';
export const IS_CHOICE_START_DATE_EMPTY = 'IsChoiceStartDateEmpty';
export const IS_DIVISION_EMPTY = 'IsDivisionEmpty';
export const IS_GROUP_MAX_EMPTY = 'IsGroupMaxEmpty';
export const IS_DURATION_EMPTY = 'IsDurationEmpty';
export const IS_PRIOR_EXPERIENCE_EMPTY = 'IsPriorExperienceEmpty';
export const IS_SNOWMOBILE_EMPTY = 'IsSnowmobileEmpty';
export const IS_LAUNCH_TIME_EMPTY = 'IsLaunchTimeEmpty';
export const IS_PH_BIRTHDATE_EMPTY = 'IsPermitHolderBirthdateEmpty';
export const IS_EC_FIRSTNAME_EMPTY = 'IsEmergencyContactFirstNameEmpty';
export const IS_EC_LASTNAME_EMPTY = 'IsEmergencyContactLastNameEmpty';
export const IS_EC_PHONE_EMPTY = 'IsEmergencyContactPhoneEmpty';
export const DOES_GROUP_SIZE_MATCH_GROUP_MEMBERS = 'DoesGroupSizeMatchGroupMembers';
export const IS_COMMENTS_EMPTY = 'IsCommentsEmpty';
export const IS_VEHICLE_LIST_EMPTY = 'IsVehicleListEmpty';

// validation targets
export const ENTRY_POINT = 'entryPoint';
export const EXIT_POINT = 'exitPoint';
export const ISSUE_STATION = 'issueStation';
export const GROUP_MEMBERS = 'groupMembers';
export const WATERCRAFTS = 'watercrafts';
export const EXIT_DATE = 'exitDate';
export const LOTTERY_OPEN_DATE = 'lotteryOpenDate';
export const LOTTERY_CLOSE_DATE = 'lotteryCloseDate';
export const LOTTERY_RUN_DATE = 'lotteryRunDate';
export const LOTTERY_DEADLINE_DATE = 'lotteryDeadlineDate';
export const LOTTERY_INFO_DISPLAY_DATE = 'lotteryInfoDisplayDate';
export const LOTTERY_NOTIFY_DATE = 'lotteryNotifyDate';
export const SEASON_START_DATE = 'lotterySeasonStartDate';
export const SEASON_END_DATE = 'lotterySeasonEndDate';
export const NEED_TO_KNOW_CHECKBOX = 'needToKnowChecked';
export const DIVISION_ID = 'divisionId';
export const SELECTED_DATES = 'selectedDates';
export const CHOICE_DATE = 'preferred_date';
export const CHOICE_DATE_RANGE_START = 'preferred_range_start';
export const CHOICE_DATE_RANGE_END = 'preferred_range_end';
export const CHOICE_DIVISION = 'division_id';
export const CHOICE_DURATION = 'length_of_stay';
export const CHOICE_GROUP_MAX = 'group_max_size';
export const CHOICE_GROUP_MIN = 'group_min_size';
export const HOUSEBOAT = 'houseboat';
export const EMERGENCY_CONTACT_FIRST_NAME = 'emergencyContactFirstName';
export const EMERGENCY_CONTACT_LAST_NAME = 'emergencyContactLastName';
export const EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone';
export const IS_VISITING_MT_WHITNEY = 'isVisitingMtWhitney';
export const IS_BRINGING_ANIMALS = 'isBringingAnimals';
export const ANIMAL_LIST = 'animalList';
export const TRAVEL_METHOD = 'travelMethodEmpty';
export const PRIOR_EXPERIENCE = 'priorExperience';
export const SNOWMOBILE = 'snowmobile';
export const LAUNCH_TIME = 'launchTime';
export const BIRTH_DATE = 'birthdate';
export const GROUP_MEMBER_FIRST_NAME = 'groupMemberFirstName';
export const GROUP_MEMBER_LAST_NAME = 'groupMemberLastName';
export const GROUP_MEMBER_AGE = 'groupMemberAge';
export const GROUP_MEMBER_START_DATE = 'groupMemberStartDate';
export const GROUP_MEMBER_END_DATE = 'groupMemberEndDate';
export const PERMIT_HOLDER_BIRTHDATE = 'permitHolderBirthdate';
export const IS_WATERCRAFT_DETAIL_EMPTY = 'watercraftDetails';
export const IS_COMMERCIALLY_GUIDED = 'isCommerciallyGuided';
export const GROUP_MEMBERS_MATCH_GROUP_SIZE = 'groupMemberList';
export const COMMENT = 'comment';
export const VEHICLE_DESCRIPTION = 'vehicleDescription';
export const LICENSE_PLATE = 'licensePlate';
export const LICENSE_STATE = 'licenseState';
export const VEHICLE_INFORMATION = 'vehicle';

export const PERMIT_HOLDER_FIRST_NAME = 'permitHolderFirstNameEmpty';
export const PERMIT_HOLDER_LAST_NAME = 'permitHolderLastNameEmpty';
export const PERMIT_HOLDER_EMAIL = 'permitHolderEmailEmpty';
export const PERMIT_HOLDER_PHONE = 'permitHolderPhoneEmpty';
export const PERMIT_HOLDER_STREET_ADDRESS = 'permitHolderStreetAddress';
export const PERMIT_HOLDER_CITY = 'permitHolderCity';
export const PERMIT_HOLDER_COUNTRY = 'permitHolderCountry';
export const PERMIT_HOLDER_STATE = 'permitHolderStateCode';
export const PERMIT_HOLDER_ZIP_CODE = 'permitHolderPostalCode';

// validation types
export const REQUIRED_BOOLEAN_FIELD = 'REQUIRED_BOOLEAN_FIELD';
export const REQUIRED_STRING_FIELD = 'REQUIRED_STRING_FIELD';
export const REQUIRED_DATE_FIELD = 'REQUIRED_DATE_FIELD';
export const REQUIRED_LIST_FIELD = 'REQUIRED_LIST_FIELD';
export const REQUIRED_NUM_FIELD = 'REQUIRED_NUM_FIELD';
export const REQUIRED_SELECTED_BOOLEAN_FIELD = 'REQUIRED_SELECTED_BOOLEAN_FIELD';
export const REQUIRED_STRING_FIELD_IN_ARRAY = 'REQUIRED_STRING_FIELD_IN_ARRAY';
export const REQUIRED_NUM_FIELD_IN_ARRAY = 'REQUIRED_NUM_FIELD_IN_ARRAY';
export const REQUIRED_DATE_FIELD_IN_ARRAY = 'REQUIRED_DATE_FIELD_IN_ARRAY';

export const PERMIT_REGISTRATION = 'PermitRegistration';
export const LOTTERY_REGISTRATION = 'LotteryRegistration';
export const REGISTRATION = 'Registration';
export const LOTTERY_PREFERENCE = 'LotteryPreference';

// validation elements
export const IsFirstNameEmpty = 'IsFirstNameEmpty';
export const IsLastNameEmpty = 'IsLastNameEmpty';
export const IsGroupMemberStartDateEmpty = 'IsGroupMemberStartDateEmpty';
export const IsGroupMemberEndDateEmpty = 'IsGroupMemberEndDateEmpty';
export const IsBirthdateEmpty = 'IsBirthdateEmpty';
export const IsAgeEmpty = 'IsAgeEmpty';
export const IsCommerciallyGuidedEmpty = 'IsCommerciallyGuidedEmpty';
export const IsVehicleDescriptionEmpty = 'IsVehicleDescriptionEmpty';
export const IsLicencePlateEmpty = 'IsLicencePlateEmpty';
export const IsLicenseStateEmpty = 'IsLicenseStateEmpty';

// detailed availability
export const AVAILABILITY_DAYS_TO_DISPLAY = 10;
export const AVAILABILITY_DAYS_TO_SKIP = 5;
export const AVAILABILITY_DAYS_TO_SKIP_MOBILE = 4;
export const AVAILABILITY_DAYS_TO_DISPLAY_MOBILE = 4;

// common date format strings
export const DATE_FORMAT = 'ddd, MMM D, YYYY';
export const DATE_TIME_FORMAT = 'ddd, MMM D, YYYY | hh:mmA z';
export const MONTH_DAY_YEAR_FORMAT = 'MMM Do YYYY';
export const AVAILABILITY_KEY_MAP_FORMAT = 'YYYY-MM-DD';
export const MONTH_DAY_YEAR_NUM_FORMAT = 'MM/DD/YYYY';
export const YEAR_MONTH_DAY_DASH_FORMAT = 'YYYY-MM-DD';
export const MONTH_ABREV_DATE_FORMAT = 'MMM DD';

// sales channel types
export const ONLINE = 'ONLINE';
export const FIELD_SALES = 'FIELD_SALES';
export const CALL_CENTER = 'CALL_CENTER';

export const FINALIZE_CHECKOUT = 'FINALIZE_CHECKOUT';
export const CLEAR_ISSUANCE = 'CLEAR_ISSUANCE';

export const DAYS_TO_SECS = 24 * 60 * 60;

// login
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';

// Error Messages
export const ERROR_UNAUTHORIZED = 'You are not authorized to access this page.';
export const ERROR_DEFAULT = 'Something went wrong, please try again later.';
export const ERROR_CLAIM_TOKEN = 'There are no claims in the auth token';
export const ERROR_TOKEN_GENERAL =
    'Our system has detected abnormal activity from your computer network. Please try refreshing the page or re-logging into your account.';

// Houseboats
export const PRIVATE_HOUSEBOAT_OWNER_TYPE = 99;

// quota rule operation types
export const RULE_OP_FIXED_START_DATE = 'FixedValueStartDate';
export const RULE_OP_FIXED_DAILY = 'FixedValueDaily';
export const RULE_OP_FIXED_BY_WATERCRAFT_PER_DAY = 'FixedValueByWatercraftPerDay';
export const RULE_OP_VALUE_BY_WATERCRAFT_PER_DAY_PERE_MARQUETTE =
    'ValueByWatercraftPerDayPereMarquette';
export const RULE_OP_FIXED_BY_MEMBER_ENTERING_PER_DAY =
    'FixedValueByMembersEnteringPerDay';
export const RULE_OP_FIXED_BY_MEMBER_ENTERING_PER_DAY_MULTIDIVISION =
    'FixedValueByMembersEnteringPerDayMultiDivision';
export const RULE_OP_MTWHITNEY_FIXED_MEMBER_EXITING_PER_DAY =
    'MtWhitneyFixedValueMembersExitingPerDay';
export const RULE_OP_FIXED_VALUE_BY_MEMBER = 'FixedValueByMembers';
export const RULE_OP_FIXED_VALUE_NIGHT_ITINERARY = 'FixedValueNightsItinerary';
export const RULE_OP_FIXED_VALUE_NIGHT_MEMBER_ITINERARY =
    'FixedValueNightsByMembersItinerary';
export const RULE_OP_FIXED_VALUE_NIGHT_BY_MEMBER =
    'FixedValueNightsByMembersMultiDivision';
export const RULE_OP_FIXED_BY_MEMBER_MULTI = 'FixedValueByMembersMultiDivision';
export const RULE_OP_FIXED_ITINERARY_MULTI_LOWER_DESCHUTES =
    'FixedValueItineraryMultiDivisionsLowerDeschutes';
export const RULE_OP_ROLLING_ALL_QUOTA_VISIBLE = 'RollingAllQuotaVisible';

// quota rule types
export const QUOTA_RULE_TYPES = {
    Available: 0,
    RegistrationSeason: 1,
    LotterySeason: 2,
    Reservation: 3,
    Modification: 4,
    LotteryCancellation: 5,
    PermitCancellation: 6,
    Fee: 7,
    Lottery: 8,
    Quota: 9,
    Visibility: 10,
    QuotaConversion: 11,
    QuotaModification: 12,
};

// DatePicker phrase constants
export const DETAILED_AVAIL_DATE_SELECT =
    'Interact with the calendar and view the detailed availability calendar starting at the selected date.';

export const ANAN_ID = '251349';
export const PERE_MARQUETTE_ID = '249987';
export const BOUNDARY_WATERS_ID = '233396';
export const CASCADES_ADVANCED_ID = '4675311';
export const CASCADES_DAILY_ID = '4675312';
export const DINOSAUR_RIVER_ID = '250014';
export const RUBY_HORSETHIEF_ID = '74466';
export const OLYMPIC_ID = '4098362';
export const GRAND_TETON_ID = '249986';
export const PACK_CREEK_ID = '233328';
export const ENCHANTMENT_ID = '233273';
export const ENCHANTMENT_CORE_DIVISION_ID = '30';
export const SELWAY_ID = '234624';
export const SAN_JUAN_ID = '250986';
export const SALT_ID = '249275';
export const MIDDLE_FORK_ID = '234623';
export const MAIN_SALMON_ID = '234622';
export const HELLS_CANYON_ID = '234625';
export const DESOLATION_GRAY_ID = '233393';
export const TILLY_JANE_ID = '233326';
export const DESOLATION_WILDERNESS_ID = '233261';
export const HELLS_CANYON_POWERBOAT_ID = '249988';
export const FOSSIL_CREEK_ID = '251863';
export const DENALI_ID = '233304';
export const HALF_DOME_ID = '234652';
export const MT_ST_HELENS_ID = '4675309';
export const PAMELIA_ID = '234769';
export const KING_RANGE_ID = '72192';
export const ANGELS_LANDING_SPRING = '4675310';
export const ANGELS_LANDING_SUMMER = '4675324';
export const ANGELS_LANDING_FALL = '4675325';
export const ANGELS_LANDING_WINTER = '4675326';

export const ANGELS_LANDING_FACILITIES = [
    ANGELS_LANDING_SPRING,
    ANGELS_LANDING_SUMMER,
    ANGELS_LANDING_FALL,
    ANGELS_LANDING_WINTER,
];

export const DINO_GREEN_YAMPA = '250014';
export const YELLOWSTONE_SNOWMOBILE_ID = '250849';

export const HALF_DOME_COMMERCIAL_DIVISION_IDS = ['33', '34'];
export const HALF_DOME_PRIVATE_DIVISION_ID = '32';
export const HALF_DOME_DAILY_DIVISION_ID = '31';

export const MIDDLE_FORK_OF_SALMON_ID = '234623';
export const SALMON_RIVER_ID = '234622';

// hunting permits
export const BLACKWATER_ID = '5121212';
export const EASTERN_NECK_ID = '5131313';
export const BLACKWATER_TURKEY_ID = '5141414';
export const BLACKWATER_WATERFOWL_ID = '5151515';
export const ALLATOONA_LAKE_ID = '5161616';
export const GEORGETOWN_LAKE_ID = '5171717';
export const BOMBAY_HOOK_ID = '5181818';
export const PRIME_HOOK_ID = '5191919';
export const BELTON_LAKE_ID = '5202020';
export const LAKE_LANIER_ID = '5212121';
export const CAROLINA_SANDHILLS_ID = '5222222';
export const WALTER_F_GEORGE_LAKE_ID = '5232323';
export const TENNESSEE_TOMBIGBEE_WATERWAY_ID = '5242424';
export const PIEDMONT_NWR_DEER_ID = '5252525';
export const LAS_VEGAS_NWR_DEER_ID = '5262626';
export const PIEDMONT_NWR_TURKEY_ID = '5272727';

export const INYO_NF_ID = '233262';
export const HUMBOLDT_TOIYABE_ID = '445856';
export const PARIA_CANYON_ID = '74984';
export const SEKI_ID = '445857';
export const SIERRA_NF_ID = '445858';
export const YOSE_NP_ID = '445859';
export const MT_MARGARET_ID = '250003';
export const CLEVELAND_PCT_ID = '445862';
export const CEDAR_MESA_BACKPACK_ID = '445861';
export const ENCHANTMENTS_DAILY_ID = '445863';
export const KING_RANGE_NG_ID = '445864';

// water based entry permits (wbe)
export const RIO_CHAMA_ID = '621743';
export const WESTWATER_ID = '621744';
export const JD_DAY_USE_ID = '621745';
export const JD_OVERNIGHT_ID = '621746';
export const WBE_CANYRIVERDAYUSE_ID = '621747';
export const WBE_CANYRIVEROVERNIGHT_ID = '621748';
export const DOLORES_RIVER_ID = '621749';
export const TUOLUMNE_RIVER_ID = '621750';
export const KERN_RIVER_ID = '621751';

// day use permits
export const SOLDIER_PASS_ID = '4251901';
export const COYOTE_BUTTES_SOUTH_ID = '16851';
export const COYOTE_BUTTES_NORTH_ID = '274309';
export const ACADIA_OTTER_CLIFFS_ID = '4251902';
export const BOB_BEAR_ID = '4251903';
export const DU_CANYONLANDS_ID = '4251904';
export const COYOTE_CANYON_ID = '4251905';
export const INDIAN_PEAKS_DU_ID = '4251906';
export const WHITE_OAK_SINK = '4251907';
export const ARCHES_CANYONEERING_ID = '4251908';
export const COYOTE_BUTTES_DAILY_ID = '4251909';

// itinerary permits
export const EVERGLADES_ID = '4675314';
export const CANYONLANDS_ID = '4675315';
export const GREAT_SAND_DUNES_ID = '4675316';
export const MT_RAINIER_ID = '4675317';
export const INDIAN_PEAKS_FULL_SEASON = '4675318';
export const INDIAN_PEAKS_3_DAYS = '4675319';
export const ROCKY_MT_ITINERARY_ID = '4675320';
export const GLACIER_ITINERARY_ID = '4675321';
export const NORTH_CASCADES_ITINERARY_ID = '4675322';
export const YELLOWSTONE_ITINERARY_ID = '4675323';
export const JOSHUA_TREE_ITINERARY_ID = '4675329';
export const BRYCE_CANYON_ITINERARY_ID = '4675330';
export const GRAND_CANYON_ITINERARY_ID = '4675337';
export const GRAND_TETON_ITINERARY_ID = '4675342';
export const DEATH_VALLEY_ID = '4675343';

export const HUNTING_PERMIT_IDS = [
    BLACKWATER_ID,
    EASTERN_NECK_ID,
    BLACKWATER_TURKEY_ID,
    BLACKWATER_WATERFOWL_ID,
    ALLATOONA_LAKE_ID,
    GEORGETOWN_LAKE_ID,
    BOMBAY_HOOK_ID,
    PRIME_HOOK_ID,
    BELTON_LAKE_ID,
    LAKE_LANIER_ID,
    CAROLINA_SANDHILLS_ID,
    WALTER_F_GEORGE_LAKE_ID,
    TENNESSEE_TOMBIGBEE_WATERWAY_ID,
    PIEDMONT_NWR_DEER_ID,
    LAS_VEGAS_NWR_DEER_ID,
    PIEDMONT_NWR_TURKEY_ID,
];
export const IS_HUNTING_PERMIT = (permitId) => HUNTING_PERMIT_IDS.includes(permitId);
export const WBE_PERMIT_IDS = [
    RIO_CHAMA_ID,
    WESTWATER_ID,
    JD_DAY_USE_ID,
    JD_OVERNIGHT_ID,
    WBE_CANYRIVERDAYUSE_ID,
    WBE_CANYRIVEROVERNIGHT_ID,
    DOLORES_RIVER_ID,
    TUOLUMNE_RIVER_ID,
    KERN_RIVER_ID,
];
export const IS_EXCLUDED_DAYUSE_VENUE_CALENDAR = (permitId) =>
    [INDIAN_PEAKS_DU_ID].includes(permitId);
export const DAYUSE_PERMIT_IDS = [
    SOLDIER_PASS_ID,
    COYOTE_BUTTES_NORTH_ID,
    COYOTE_BUTTES_SOUTH_ID,
    ACADIA_OTTER_CLIFFS_ID,
    BOB_BEAR_ID,
    DU_CANYONLANDS_ID,
    COYOTE_CANYON_ID,
    INDIAN_PEAKS_DU_ID,
    WHITE_OAK_SINK,
    ARCHES_CANYONEERING_ID,
    COYOTE_BUTTES_DAILY_ID,
];
export const ITINERARY_PERMIT_IDS = [
    EVERGLADES_ID,
    CANYONLANDS_ID,
    GREAT_SAND_DUNES_ID,
    MT_RAINIER_ID,
    INDIAN_PEAKS_FULL_SEASON,
    INDIAN_PEAKS_3_DAYS,
    OLYMPIC_ID,
    ROCKY_MT_ITINERARY_ID,
    GLACIER_ITINERARY_ID,
    NORTH_CASCADES_ITINERARY_ID,
    YELLOWSTONE_ITINERARY_ID,
    JOSHUA_TREE_ITINERARY_ID,
    BRYCE_CANYON_ITINERARY_ID,
    GRAND_TETON_ITINERARY_ID,
    DEATH_VALLEY_ID,
];
export const IS_ITINERARY_PERMIT = (permitId) => ITINERARY_PERMIT_IDS.includes(permitId);
export const LAND_PERMIT_IDS = [
    INYO_NF_ID,
    HUMBOLDT_TOIYABE_ID,
    PARIA_CANYON_ID,
    SEKI_ID,
    SIERRA_NF_ID,
    YOSE_NP_ID,
    ENCHANTMENTS_DAILY_ID,
    KING_RANGE_NG_ID,
];
export const IS_BOUNDARY_WATER = (permitId) => [BOUNDARY_WATERS_ID].includes(permitId);

export const IS_PERMIT_WITH_STRICT_APH = (permitId) =>
    [
        COYOTE_BUTTES_SOUTH_ID,
        COYOTE_BUTTES_NORTH_ID,
        PARIA_CANYON_ID,
        COYOTE_BUTTES_DAILY_ID,
    ].includes(permitId);

export const NON_CENTRAL_SIERRA_LBE_LOCATIONS = [
    KING_RANGE_NG_ID,
    PARIA_CANYON_ID,
    CLEVELAND_PCT_ID,
];
// below requires a permit mapping passed in to verify its condition being met
export const IS_HUNTING_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'hunting_permit_ids', permitMapping);
export const IS_WATER_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'water_permit_ids', permitMapping);
export const IS_DAYUSE_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'day_use_permit_ids', permitMapping);
export const IS_LAND_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'land_permit_ids', permitMapping);
export const IS_ITINERARY_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'itinerary_permit_ids', permitMapping);
export const IS_LOTTERY_PERMIT = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'lottery_permit_ids', permitMapping);
export const IS_EARLY_ACCESS_PERMIT = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'early_access_permit_ids', permitMapping);
export const IS_VEHICLE_PERMIT_V2 = (permitId, permitMapping) =>
    matchPermitTypeFromMapping(permitId, 'vehicle_permit_ids', permitMapping);

export const IS_LEGACY_PERMIT_V2 = (permitId, permitMapping) => {
    return (
        !IS_HUNTING_PERMIT_V2(permitId, permitMapping) &&
        !IS_WATER_PERMIT_V2(permitId, permitMapping) &&
        !IS_DAYUSE_PERMIT_V2(permitId, permitMapping) &&
        !IS_LAND_PERMIT_V2(permitId, permitMapping) &&
        !IS_ITINERARY_PERMIT_V2(permitId, permitMapping) &&
        !IS_VEHICLE_PERMIT_V2(permitId, permitMapping)
    );
};

export const FALLBACK_PERMIT_MAPPING = {
    hunting_permit_ids: HUNTING_PERMIT_IDS,
    water_permit_ids: WBE_PERMIT_IDS,
    day_use_permit_ids: DAYUSE_PERMIT_IDS,
    land_permit_ids: LAND_PERMIT_IDS,
    itinerary_permit_ids: ITINERARY_PERMIT_IDS,
};

// PERMIT_ENDPOINT_V2 variable is used by both legacy and non-legacy permits, thus needs this conditional
export const PERMIT_ENDPOINT_V2 = (permitId, permitMapping) =>
    IS_LEGACY_PERMIT_V2(permitId, permitMapping)
        ? `${process.env.API}/permits/${permitId}/details`
        : `${process.env.API}/permitcontent/${permitId}`;

// PERMIT_CONTENT_ENDPOINT variable is used by legacy permits for content only
export const PERMIT_CONTENT_ENDPOINT = (permitId) =>
    `${process.env.API}/permitcontent/${permitId}`;

export const ISSUANCE_ENDPOINT = (permitId, issuanceId, permitMapping) =>
    IS_LEGACY_PERMIT_V2(permitId, permitMapping)
        ? `${process.env.API}/permits/${permitId}/issuances/${issuanceId}/account`
        : `${process.env.API}/permitissuance/${permitId}/issuances/${issuanceId}`;

export const CANCEL_ENDPOINT = (permitId, issuanceId, permitMapping) => {
    if (IS_WATER_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitwbe/${permitId}/lottery/issuances/${issuanceId}/cancel`;
    }
    if (IS_HUNTING_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/huntingpermit/${permitId}/lottery/issuances/${issuanceId}/cancel`;
    }
    if (IS_DAYUSE_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitdayuse/${permitId}/lottery/issuances/${issuanceId}/cancel`;
    }
    if (IS_LAND_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitinyo/${permitId}/lottery/issuances/${issuanceId}/cancel`;
    }

    return `${process.env.API}/permits/${permitId}/issuances/${issuanceId}/cancel`;
};

export const PDF_ENDPOINT = (permitId, issuanceId, permitMapping) => {
    if (IS_HUNTING_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/huntingpermit/permit/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_DAYUSE_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitdayuse/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_WATER_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitwbe/permits/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_ITINERARY_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permititinerary/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_LAND_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitinyo/permit/${permitId}/issuances/${issuanceId}/pdf`;
    }

    return IS_LEGACY_PERMIT_V2(permitId, permitMapping)
        ? `${process.env.API}/permits/${permitId}/issuances/${issuanceId}/pdf`
        : `${process.env.API}/permitissuance/${permitId}/issuances/${issuanceId}`;
};

export const INTERNAL_PDF_ENDPOINT_V2 = (permitId, issuanceId, permitMapping) => {
    if (IS_HUNTING_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/huntingpermit/permit/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_DAYUSE_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitdayuse/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_WATER_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitwbe/internal/permits/${permitId}/issuances/${issuanceId}/pdf`;
    }
    if (IS_LAND_PERMIT_V2(permitId, permitMapping)) {
        return `${process.env.API}/permitinyo/internal/permit/${permitId}/issuances/${issuanceId}/pdf`;
    }

    return IS_LEGACY_PERMIT_V2(permitId, permitMapping)
        ? `${process.env.API}/permits/internal/${permitId}/issuances/${issuanceId}/pdf`
        : `${process.env.API}/permitissuance/${permitId}/issuances/${issuanceId}`;
};

export const CANCELLATION_ENDPOINT = (permitId, issuanceId, permitMapping) =>
    IS_LEGACY_PERMIT_V2(permitId, permitMapping)
        ? `${process.env.API}/permits/${permitId}/issuances/${issuanceId}/cancel`
        : `${process.env.API}/permitgateway/${permitId}/issuances/${issuanceId}/cancel`;

export const PAY_BALANCE_ENDPOINT = (permitId, issuanceId) => {
    return `${process.env.API}/permitgateway/${permitId}/issuances/${issuanceId}/balancepayment`;
};

export const ANAN_COMPANY_LIST = [
    'Alaska Charters & Adventures',
    "Alaska Peak & Sea's",
    'Alaska Vistas',
    'Alaska Waters',
    'Alaska Yacht Guides',
    'All Aboard Yacht Charters',
    'Aqua Sports Enterprises',
    'Bluewater Adventures',
    'Breakaway Adventures',
    'Carlin Air',
    'Dolphin Charters',
    'Family Air Tours',
    'Island Wings',
    'Muddy Water Adventures',
    'Southeast Aviation',
    'Stickeen Wilderness Adventures',
    'Sunrise Aviation',
    'Taquan Air Service',
];

export const RUBY_GUEST_TYPES = [
    {
        key: 'adult',
        type: 'Adult',
        description: 'ages 17+',
    },
    {
        key: 'child',
        type: 'Child',
        description: 'ages 0 - 16',
    },
    {
        key: 'dog',
        type: 'Dog',
        description: 'no age required',
    },
];

export const RUBY_BLACKROCK_DIV_IDS = [
    '74466022',
    '74466023',
    '74466024',
    '74466025',
    '74466026',
    '74466027',
    '74466028',
    '74466029',
    '74466030',
];
export const WEEKEND_STAY_LIMIT_IDS = [].concat(RUBY_BLACKROCK_DIV_IDS);

export const OVERRIDE_MAX_VALUE = 100;
export const OVERRIDE_STAY_LIMIT_MAX = 30;
// export const MAX_STOCK_KEY_NAME = 'MaxStock';

// OLYMPIC
export const OLYMPIC_DISTRICT_NAMES = [
    'Staircase',
    'Dosewallips',
    'Hurricane',
    'Elwha',
    'Sol Duc',
    'Bogachiel',
    'Hoh',
    'Queets',
    'Quinault',
    'North Coast',
    'South Coast',
    'Bailey Range',
    'Lake Crescent',
];
// OLYMPIC Permittypes
export const OVERNIGHT = 'Overnight';
export const OVERNIGHT_WITH_STOCK = 'Overnight with stock';

export const CASCADES_DISTRICT_NAMES = [
    'Three Sisters',
    'Mt. Jefferson',
    'Mt. Washington',
];

export const GET_DISTRICT_MAP = (permitId) => {
    switch (permitId) {
        case OLYMPIC_ID:
            return OLYMPIC_DISTRICT_NAMES;
        case CASCADES_ADVANCED_ID:
        case CASCADES_DAILY_ID:
            return CASCADES_DISTRICT_NAMES;
        default:
            return [];
    }
};

// Special Validation Rules
// For permits where stock is required, but only for certain permittypes
export const CONDITIONAL_ANIMALS_REQUIRED = 'IsAnimalsEmptyOnStock';

// AvailabilityCell Statuses
export const availabilityCellStatuses = {
    WALK_UP: 'Walk-Up',
    UNAVAILABLE: 'Unavailable',
    AVAILABLE: 'Available',
    SELECTED: 'Selected',
    LOTTERY: 'Lottery',
};

export const selectedAvailabilityCellStatuses = {
    AVAILABILITY_START_MULTIDAY: 'Selected as Start Date',
    AVAILABILITY_CONTINUE_MULTIDAY: 'Selected',
    AVAILABILITY_END_MULTIDAY: 'Selected as End Date',
};

export const CAMPSITE_PREFERENCES_DELIMITER = '::';

export const SET_VALIDATION_ERROR = 'SET_VALIDATION_ERROR';

export const LOCATIONS_TO_EXCLUDE_RENTALS_PROMO = [
    '233396',
    '251349',
    '249991',
    '233304',
    '233328',
];

export const PERMIT_TYPES_WITH_FILTERED_DIVISIONS = ['44585892'];
