/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Heading, HelmetWrapperInternal, FlexRow, FlexCol, TYPES } from 'sarsaparilla';

import SiteWrapper from './SiteWrapper';
import { displayStringForRoleType } from '../utilities/roles';
import {
    domainToTileMap,
    launchBuckets,
    generateTilePath,
} from '../utilities/launchTiles';
import { getDomainsForLocation } from '../actions/fetchChildLocations';
import { getLocationDisplayName } from '../..';

const propTypes = {
    history: TYPES.HISTORY,
    roleSelection: PropTypes.shape({
        location: PropTypes.string,
        role_type: PropTypes.string,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    domainsForLocationId: PropTypes.object,
    getDomainsForLocation: PropTypes.func,
};

export class LaunchPad extends React.Component {
    static propTypes = propTypes;

    componentDidMount() {
        this.props.getDomainsForLocation(
            this.props.roleSelection.location,
            this.props.history
        );
    }

    renderBuckets = () => {
        const buckets = Object.keys(launchBuckets);
        return buckets.map((name) => {
            return (
                <section className={`launch-bucket launch-bucket-${name}`} key={name}>
                    <Heading headingLevel={2} appearance="h6" className="mt-3">
                        {launchBuckets[name]}
                    </Heading>
                    <ul>{this.renderLinks(name)}</ul>
                </section>
            );
        });
    };

    renderLinks = (bucketName) => {
        const tiles = [];
        if (
            this.props.domainsForLocationId &&
            this.props.roleSelection &&
            this.props.roleSelection.location
        ) {
            const loc = this.props.roleSelection.location;
            const key = `${loc.location_id}_${loc.location_type}`;
            const domains = this.props.domainsForLocationId[key]
                ? this.props.domainsForLocationId[key].domains
                : null;
            if (domains) {
                domains.forEach((domain, index) => {
                    const tile = domainToTileMap[domain];
                    if (tile && tile.bucket === bucketName) {
                        const path = generateTilePath(
                            tile,
                            this.props.roleSelection.location
                        );
                        tiles.push(
                            <li key={index}>
                                <a href={path}>
                                    <div className="rec-title">{tile.text}</div>
                                </a>
                            </li>
                        );
                    }
                });
            }
        }

        if (tiles.length === 0) {
            tiles.push(<li>No Options Available at this Location</li>);
        }

        return tiles;
    };

    render() {
        const { roleSelection } = this.props;

        const roleType =
            displayStringForRoleType(this.props.roleSelection.role_type) || 'N/A';
        const locationDisplayName = getLocationDisplayName(roleSelection.location);

        return (
            <div className="site-map-page">
                <HelmetWrapperInternal title="Site Map" />
                <SiteWrapper>
                    <main className="site-map-main">
                        <FlexRow>
                            <FlexCol xs={12}>
                                <Heading
                                    headingLevel={1}
                                    appearance="h2"
                                    className="mt-4 mb-6"
                                >
                                    Site Map
                                </Heading>

                                <section>
                                    <Heading
                                        headingLevel={2}
                                        appearance="h4"
                                        className="mb-1"
                                    >
                                        Role/location-specific links
                                    </Heading>

                                    {!!roleSelection && (
                                        <p className="mb-4">
                                            These are the options that are available for{' '}
                                            {roleType} at {locationDisplayName}:
                                        </p>
                                    )}

                                    {this.renderBuckets()}
                                </section>

                                <section className="mt-6 mb-3">
                                    <Heading
                                        headingLevel={2}
                                        appearance="h4"
                                        className="mb-1"
                                    >
                                        Other links
                                    </Heading>
                                    <ul>
                                        {!isEmpty(process.env.SN_CONTACT_INT_URL) && (
                                            <li>
                                                <a
                                                    href={process.env.SN_CONTACT_INT_URL}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Contact Us
                                                </a>
                                            </li>
                                        )}

                                        {!isEmpty(process.env.SN_KB_INT_URL) && (
                                            <li>
                                                <a
                                                    href={process.env.SN_KB_INT_URL}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Help Center
                                                </a>
                                            </li>
                                        )}

                                        {!isEmpty(process.env.SN_CASES_URL) && (
                                            <li>
                                                <a
                                                    href={process.env.SN_CASES_URL}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View My Cases
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </section>
                            </FlexCol>
                        </FlexRow>
                    </main>
                </SiteWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const loginDomains = state.login.account
        ? state.login.account.domains_by_location_id
        : {};

    return {
        roleSelection: state.selectedLocationRole.role,
        domainsForLocationId:
            state.fetchChildLocations.selected_domains_by_location_id || loginDomains,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDomainsForLocation: (location, history) =>
            dispatch(getDomainsForLocation(location, history)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchPad);
